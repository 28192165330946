import { TableFieldsConfig } from '../search_old/types';
import { siteCherryPickFieldConfig } from './fields/siteCherryPickFieldConfig';
import { siteCountryFieldConfig } from './fields/siteCountryFieldConfig';
import { siteNameFieldConfig } from './fields/siteNameFieldConfig';
import { siteName2FieldConfig } from './fields/siteName2FieldConfig';
import { siteTypeFieldConfig } from './fields/siteTypeFieldConfig';
import { siteVisitStreetFieldConfig } from './fields/siteVisitStreetFieldConfig';
import { siteVisitCityFieldConfig } from './fields/siteVisitCityFieldConfig';
import { siteVisitZipFieldConfig } from './fields/siteVisitZipFieldConfig';
import { siteBrickFieldConfig } from './fields/siteBrickFieldConfig';
import { siteCountyFieldConfig } from './fields/siteCountyFieldConfig';
import { siteSpecialtiesFieldConfig } from './fields/siteSpecialtiesFieldConfig';
import { siteWebSiteFieldConfig } from './fields/siteWebSiteFieldConfig';
import { siteAddressFieldConfig } from './fields/siteAddressFieldConfig';
import { buildEntityCustomFieldConfigs } from './fields/buildEntityCustomFieldConfigs';
import { siteCreatedFieldConfig } from './fields/siteCreatedFieldConfig';
import { sitePureAdvanceIdFieldConfig } from './fields/sitePureAdvanceIdFieldConfig';
import { siteImportedFieldConfig } from './fields/siteImportedFieldConfig';
import { siteMaintainerSourceFieldConfig } from './fields/siteMaintainerSourceFieldConfig';
import { siteOriginSourceFieldConfig } from './fields/siteOriginSourceFieldConfig';
import { siteUpdatedFieldConfig } from './fields/siteUpdatedFieldConfig';
import { siteValidatedFieldConfig } from './fields/siteValidatedFieldConfig';
import { buildEntityContactInformationFieldConfigs } from '../person/fields/buildEntityContactInformationFieldConfigs';
import { siteParentNameFieldConfig } from './fields/siteParentNameFieldConfig';
import { siteMailZipCodeFieldConfig } from './fields/siteMailZipCodeFieldConfig';
import { siteMailStreetFieldConfig } from './fields/siteMailStreetFieldConfig';
import { siteMailCityFieldConfig } from './fields/siteMailCityFieldConfig';
import { siteChainFieldConfig } from './fields/siteChainFieldConfig';
import { siteNumberOfChairsFieldConfig } from './fields/siteNumberOfChairsFieldConfig';
import { siteNumberOfCBedsFieldConfig } from './fields/siteNumberOfBedsFieldConfig';
import { EntityTypeEnum } from '../../../gql/typings';
import { siteSectorTypeFieldConfig } from './fields/siteSectorTypeFieldConfig';
import { buildEntityExternalIdFieldConfigs } from './fields/buildEntityExternalIdFieldConfigs';
import { siteSegmentationFieldConfig } from './fields/siteSegmentationFieldConfig';
import { siteVisitAddressLine2FieldConfig } from './fields/siteVisitAddressLine2FieldConfig';
import { siteMailAddressLine2FieldConfig } from './fields/siteMailAddressLine2FieldConfig';
import { buildApureBaseIdFieldConfig } from '../person/fields/personApureBaseIdFieldConfig';
import { siteTerritoryFieldConfig } from './fields/siteTerritoryFieldConfig';
import { siteTerritoryResponsibleUserFieldConfig } from './fields/siteTerritoryResponsibleUserFieldConfig';
import { siteResponsibleUserFieldConfig } from './fields/siteResponsibleUserFieldConfig';

export type SupportedSiteTableColumns =
  'country'
  | 'name'
  | 'name2'
  | 'type'
  | 'visitStreet'
  | 'visitAddressLine2'
  | 'visitCity'
  | 'visitZip'
  | 'mailStreet'
  | 'mailAddressLine2'
  | 'mailZip'
  | 'mailCity'
  | 'brick'
  | 'county'
  | 'specialties'
  | 'website'
  | 'address'
  | 'customField'
  | 'created'
  | 'updated'
  | 'validated'
  | 'imported'
  | 'id'
  | 'aPureBaseId'
  | 'originSource'
  | 'maintainerSource'
  | 'contactInformation'
  | 'parentName'
  | 'cherryPick'
  | 'chain'
  | 'numberOfChairs'
  | 'numberOfBeds'
  | 'siteSectorType'
  | 'externalId'
  | 'segmentation'
  | 'territory'
  | 'territoryResponsibleUser'
  | 'responsibleUser';

export const supportedSiteTableColumns: TableFieldsConfig<SupportedSiteTableColumns>['fields'] = {
  country: siteCountryFieldConfig,
  name: siteNameFieldConfig,
  name2: siteName2FieldConfig,
  parentName: siteParentNameFieldConfig,
  type: siteTypeFieldConfig,
  visitStreet: siteVisitStreetFieldConfig,
  visitAddressLine2: siteVisitAddressLine2FieldConfig,
  visitCity: siteVisitCityFieldConfig,
  visitZip: siteVisitZipFieldConfig,
  mailStreet: siteMailStreetFieldConfig,
  mailAddressLine2: siteMailAddressLine2FieldConfig,
  mailCity: siteMailCityFieldConfig,
  mailZip: siteMailZipCodeFieldConfig,
  cherryPick: siteCherryPickFieldConfig,
  brick: siteBrickFieldConfig,
  county: siteCountyFieldConfig,
  specialties: siteSpecialtiesFieldConfig,
  website: siteWebSiteFieldConfig,
  address: siteAddressFieldConfig,
  customField: buildEntityCustomFieldConfigs(EntityTypeEnum.SITE),
  aPureBaseId: buildApureBaseIdFieldConfig(EntityTypeEnum.SITE),
  created: siteCreatedFieldConfig,
  id: sitePureAdvanceIdFieldConfig,
  imported: siteImportedFieldConfig,
  maintainerSource: siteMaintainerSourceFieldConfig,
  originSource: siteOriginSourceFieldConfig,
  updated: siteUpdatedFieldConfig,
  validated: siteValidatedFieldConfig,
  contactInformation: buildEntityContactInformationFieldConfigs(EntityTypeEnum.SITE),
  chain: siteChainFieldConfig,
  numberOfChairs: siteNumberOfChairsFieldConfig,
  numberOfBeds: siteNumberOfCBedsFieldConfig,
  siteSectorType: siteSectorTypeFieldConfig,
  externalId: buildEntityExternalIdFieldConfigs(EntityTypeEnum.SITE),
  segmentation: siteSegmentationFieldConfig,
  territory: siteTerritoryFieldConfig,
  territoryResponsibleUser: siteTerritoryResponsibleUserFieldConfig,
  responsibleUser: siteResponsibleUserFieldConfig,
};
