import React, { useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useUpdateEffect } from 'ahooks';
import { Badge, Divider, Form, Input, message, Row, Skeleton, Tabs, Typography } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useLocalization } from '../../../util/useLocalization';
import { CreateCustomFieldFormData } from './CreateCustomField';
import { PersonDetails_DATA_QUERY } from '../../person/PersonDetails/PersonDetails';
import { SITE_DATA_QUERY } from '../../site/SiteDetails';
import { ACTIVITY_NOTE_DATA_QUERY } from '../../activity/ActivityDetails/ActivityNoteContainer';
import { DEFAULT_CRITERIAS_DATA_QUERY } from '../../../components/entitiesSearch';
import { Locale } from '../../../../localization/LocalizationKeys';
import { customFieldTypeOptionSupport } from './customFieldUtils';
import CreateCustomFieldInputItemField from './CreateCustomFieldInputItemField';
import CustomFieldSelectType from './components/CustomFieldSelectType';
import CustomFieldConfigurationTab from './tabs/CustomFieldConfigurationTab';
import CustomFieldPermissionConfigurationTab from './tabs/CustomFieldPermissionConfigurationTab';
import CustomFieldUsageOverviewTab from './tabs/CustomFieldUsageOverviewTab';
import {
  UpdateCustomFieldQueryQuery,
  UpdateCustomFieldQueryQueryVariables,
  UpdateCustomSelectFieldMutationMutation, UpdateCustomSelectFieldMutationMutationVariables
} from '../../../../gql/typings';

type UpdateCustomFieldProps = {
  customFieldCode: string;
};

const UpdateCustomField: React.FC<UpdateCustomFieldProps> = ({
  customFieldCode,
}) => {
  const [form] = Form.useForm<CreateCustomFieldFormData>();
  const localization = useLocalization();
  const { data, loading, refetch } = useQuery<UpdateCustomFieldQueryQuery, UpdateCustomFieldQueryQueryVariables>(DATA_QUERY, {
    variables: { customFieldCode },
  });
  const [
    update
  ] = useMutation<UpdateCustomSelectFieldMutationMutation, UpdateCustomSelectFieldMutationMutationVariables>(UPDATE_MUTATION);

  useUpdateEffect(() => {
    if (!loading && customFieldCode) refetch({ customFieldCode });
  }, [customFieldCode]);

  useEffect(() => {
    if (data?.customField) {
      form.setFieldsValue({
        code: data.customField.code,
        name: data.customField.heading,
        type: data.customField.fieldType.enum ?? undefined,
        colorConfiguration: data.customField.color,
        allowMultiple: data.customField.allowMultiple,
        allowUserCreate: data.customField.allowUserCreate,
        isCheckableTag: data.customField.isCheckableTag,
        isActive: data.customField.isActive || true,
        allowCountrySpecificValues: data.customField.allowCountrySpecificValues || false,
        options: data.customField.options?.nodes?.map(n => ({
          id: n.id,
          value: n.value?.value ?? '',
          countries: n.selectedCountries.nodes.map(sc => sc.country),
          count: n.relations.totalCount,
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, data]);

  const onFinish = () => {
    const {
      name,
      type,
      options,
      colorConfiguration,
      isCheckableTag,
      isActive,
      allowUserCreate,
      allowMultiple,
      allowCountrySpecificValues,
    } = form.getFieldsValue();
    return update({
      variables: {
        input: {
          code: customFieldCode,
          type,
          color: colorConfiguration,
          allowMultiple,
          isCheckableTag,
          allowUserCreate,
          allowCountrySpecificValues,
          isActive,
          options: customFieldTypeOptionSupport(type) && options
            ? options.map(o => ({ optionId: o.id, value: o.value, countries: o.countries }))
            : [],
          heading: name,
          entityType: data!.customField!.entityTypeEnum,
        },
      },
      refetchQueries: [
        PersonDetails_DATA_QUERY,
        SITE_DATA_QUERY,
        ACTIVITY_NOTE_DATA_QUERY,
        DEFAULT_CRITERIAS_DATA_QUERY,
      ],
    }).then(res => {
      if ((res.errors?.length ?? 0) > 0) return message.error(res.errors![0]!.message);
    }).finally(message.success(localization.formatMessage(Locale.Text.Update_Grid_Success)));
  };

  const onFinishFailed = (info: ValidateErrorEntity) => {
    const error = info.errorFields[0]?.errors?.[0];
    if (error) return message.error(error);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Typography.Title level={3}>
        {localization.formatMessage(Locale.Text.Update_custom_field)}
      </Typography.Title>

      <Divider style={{ marginTop: 8 }} />

      <Row gutter={[0, 12]}>
        <CreateCustomFieldInputItemField
          name="code"
          nameResource={Locale.Attribute.Code}
          tooltipResource={Locale.Attribute.Code}
          rules={[{ required: true, message: localization.formatMessage(Locale.Text.Name_required) }]}
        >
          <Input disabled />
        </CreateCustomFieldInputItemField>
        <CreateCustomFieldInputItemField
          name="name"
          nameResource={Locale.Attribute.Name}
          tooltipResource={Locale.Attribute.Name}
          rules={[{ required: true, message: localization.formatMessage(Locale.Text.Name_required) }]}
        >
          <Input />
        </CreateCustomFieldInputItemField>
        <CreateCustomFieldInputItemField
          name="type"
          nameResource={Locale.Attribute.Type}
          tooltipResource={Locale.Attribute.Type}
          rules={[{ required: true, message: localization.formatMessage(Locale.Text.Name_required) }]}
        >
          <CustomFieldSelectType isCreated />
        </CreateCustomFieldInputItemField>
      </Row>
      <Skeleton loading={!data?.customField} active={loading}>
        {data?.customField && <Tabs
          style={{ marginTop: 8 }}
          items={[
            {
              key: 'configuration',
              label: 'Field Configuration',
              children: <CustomFieldConfigurationTab
                form={form}
                field={null}
                fieldType={data.customField.fieldType.enum}
                entityType={data.customField.entityTypeEnum}
                loading={loading}
              />
            },
            {
              key: 'permission',
              label: 'Field Permissions',
              children: <CustomFieldPermissionConfigurationTab customFieldCode={customFieldCode} />
            },
            {
              key: 'usages',
              label: (
                <>
                  {localization.formatMessage(Locale.General.Usages)}
                  &nbsp;
                  <Badge
                    className="primary-badge"
                    showZero
                    count={data?.customField.fieldGridConfigurations.totalCount}
                  />
                </>
              ),
              children: <CustomFieldUsageOverviewTab customFieldCode={customFieldCode} />
            }
          ]}
        />}
      </Skeleton>
    </Form>
  );
};

const UPDATE_MUTATION = gql`
  mutation UpdateCustomSelectFieldMutation($input: UpdateCustomFieldInput!) {
    updateCustomField(input: $input) {
      code
      heading
    }
  }
`;


const DATA_QUERY = gql`
  query UpdateCustomFieldQuery($customFieldCode: String!) {
    customField(code: $customFieldCode) {
      code
      heading
      color
      isActive
      allowMultiple
      allowUserCreate
      isCheckableTag
      allowCountrySpecificValues
      entityTypeEnum
      fieldType {
        code
        enum
      }
      fieldGridConfigurations {
        hash
        totalCount
      }
      options(criteria: { fetchSize: { limit: null } }) {
        hash
        nodes {
          id
          selectedCountries(criteria: { fetchSize: { limit: null } }) {
            hash
            nodes {
              id
              country
            }
          }
          relations {
            hash
            totalCount
          }
          value {
            id
            value
          }
        }
      }
    }
  }
`;

export default UpdateCustomField;
