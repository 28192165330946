import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Select } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { UsersForSelectQuery, UsersForSelectQueryVariables } from '../../gql/typings';
import { Optional } from '../util/StateArrayType';

type UserSelectorProps = {
  value?: Optional<number>;
  onChange?: (value?: Optional<number>) => void;
  size?: SizeType;
  loading?: boolean;
  startOpen?: true;
};

const UserSelector: React.FC<UserSelectorProps> = ({
  value,
  onChange,
  loading,
  size = 'small',
  startOpen,
}) => {
  const { data, loading: usersLoading } = useQuery<UsersForSelectQuery, UsersForSelectQueryVariables>(DATA_QUERY);
  return (
    <Select
      size={size}
      style={{ width: '100%' }}
      onChange={onChange}
      value={value}
      loading={loading || usersLoading}
      defaultOpen={startOpen}
      showSearch
      autoFocus
      filterOption={(input, option) => (option?.label.toLowerCase().indexOf(input.toLowerCase()) ?? 1) >= 0}
      options={(data?.users.nodes ?? []).map(u => ({
        value: u.id,
        label: u.fullname,
      }))}
    />
  );
};

export default UserSelector;

const DATA_QUERY = gql`
  query UsersForSelect {
    users(criteria: { fetchSize: { limit: 10000 } }) {
      hash
      totalCount
      nodes {
        id
        fullname
      }
    }
  }
`;
