import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Col, Modal, Row, Tooltip } from 'antd';
import { CustomFieldProps, REMOVE_MUTATION, UPSERT_MUTATION } from './CustomFieldUtils';
import Icon from '../Icon/Icon';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import {
  RemoveCustomFieldValueMutation, RemoveCustomFieldValueMutationVariables,
  UpdateSelectCustomFieldValueForRecord,
  UpsertCustomFieldValueMutation,
  UpsertCustomFieldValueMutationVariables
} from '../../../gql/typings';
import TransferComponent from '../TransferModal/TransferComponent';


const CustomFieldMultiSelect: React.FC<CustomFieldProps> = ({
  field,
  values,
  saveState: [, save],
}) => {
  const localization = useLocalization();
  const [isEditable, setEditable] = useState(false);
  const [
    removeCustomFieldValue
  ] = useMutation<RemoveCustomFieldValueMutation,
  RemoveCustomFieldValueMutationVariables>(REMOVE_MUTATION);

  const [
    upsert, { loading }
  ] = useMutation<UpsertCustomFieldValueMutation,
  UpsertCustomFieldValueMutationVariables>(UPSERT_MUTATION);

  const transferSelected = useState<string[]>([]);

  useEffect(() => {
    transferSelected[1](values.nodes.map(it => it.value!.id.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const settings = field.customFieldControlSetting;
  const dataSource = field.options?.nodes.map(item => ({
    key: item.value!.id.toString(),
    title: item.value!.value ?? '',
  })) ?? [];

  const onCancel = () => {
    transferSelected[1](values.nodes.map(it => it.value!.id.toString()));
    setEditable(false);
  };

  const onChange = () => {
    const selectedValues: UpdateSelectCustomFieldValueForRecord[] = transferSelected[0]
      .map(ns => ({ valueId: parseInt(ns, 10) }));
    if (selectedValues.length > 0) save(prev => ([
      ...prev,
      recordId => upsert({
        variables: {
          customFieldCode: field.code,
          recordId,
          customValues: selectedValues,
        }
      })
    ])); else save(prev => ([
      ...prev,
      () => removeCustomFieldValue({
        variables: {
          customValues: values?.nodes.map(v => v.id),
        }
      })
    ]));
    setEditable(false);
  };

  const loopValues = () => transferSelected[0]
    .map(valueKey => dataSource.filter(i => i.key == valueKey)[0])
    .filter(m => m) as unknown as ({ key: string; title: string }[]);

  if (!field.accessRules?.allowMutation) return (
    <span className='comma-list'>
      {loopValues().map(v => (
        <span className="comma" key={`${v.key}-fil`}>
          {v.title}
        </span>
      ))}
    </span>
  );

  return (
    <Row className="custom-field-select-grid">
      <Col span={settings?.isSynced ? 23 : 24}>
        <Tooltip
          title={field.accessRules?.allowMutation
            ? '' : localization.formatMessage(Locale.Text.Mutation_permission_denied)}
        >
          <div className='custom-field-multi-select' onClick={() => setEditable(true)}>
            <div className='custom-multi-select-selection-overflow'>
              {loopValues().map(v => (
                <div key={v.key} className="custom-multi-select-selection-overflow-item">
                  <div className="custom-multi-select-selection-item">
                    <div className="custom-multi-select-selection-item-content">
                      {v.title}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {
            isEditable && <Modal
              title='Multi'
              open
              onCancel={onCancel}
              confirmLoading={loading}
              width='clamp(320px, 75%, 900px)'
              footer={[
                <Button key="1" onClick={onCancel}>
                  {localization.formatMessage(Locale.Command.Cancel)}
                </Button>,
                <Button
                  key="2"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={onChange}
                >
                  {localization.formatMessage(Locale.Command.Save)}
                </Button>,
              ]}
            >
              <TransferComponent dataSource={dataSource} selectedKeys={transferSelected} />
            </Modal>
          }
        </Tooltip>
      </Col>
      {settings?.isSynced && <Col span={1}>
        <Icon toolTip="is synchronized" style={{ marginTop: 8, marginLeft: 8 }} iconType="SYNC" />
      </Col>}
    </Row>
  );
};

export default CustomFieldMultiSelect;
