import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Row, Switch, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { CustomFieldProps, UPSERT_MUTATION } from './CustomFieldUtils';
import { extractBooleanValue } from '../../util/Util';
import Icon from '../Icon/Icon';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';


const CustomFieldSwitch: React.FC<CustomFieldProps> = ({
  field,
  values,
  saveState: [, save],
}) => {
  const localization = useLocalization();
  const selectedValue = !!values.nodes[0];
  const defaultValue = selectedValue ? extractBooleanValue(values.nodes[0]!.value!.value) : false;
  const [isChecked, setIsChecked] = useState(defaultValue);
  const [
    upsert
  ] = useMutation(UPSERT_MUTATION);

  useEffect(() => {
    setIsChecked(defaultValue);
  }, [defaultValue]);

  const onChange = (checked: boolean) => {
    setIsChecked(checked);
    save(prev => ([
      ...prev,
      recordId => upsert({
        variables: {
          recordId,
          customFieldCode: field.code,
          customValues: [{ newValue: checked ? 'true' : 'false' }],
        }
      }),
    ]));
  };


  const settings = field.customFieldControlSetting;

  return (
    <Row className="custom-field-select-grid">
      <Col span={settings?.isSynced ? 23 : 24}>
        <Tooltip
          title={field.accessRules?.allowMutation
            ? '' : localization.formatMessage(Locale.Text.Mutation_permission_denied)}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            disabled={!field?.accessRules?.allowMutation}
            onChange={onChange}
            checked={isChecked}
          />
        </Tooltip>
      </Col>
      {settings?.isSynced && (
        <Col span={1}>
          <Icon toolTip="is synchronized" style={{ marginTop: 8, marginLeft: 8 }} iconType="SYNC" />
        </Col>
      )}
    </Row>
  );
};

export default CustomFieldSwitch;
