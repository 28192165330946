import React, { useState } from 'react';
import { Button, Col, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import {
  SiteBrickTerritoryQueryQuery, UpdateTerritoryResponsibleMutation, UpdateTerritoryResponsibleMutationVariables,
} from '../../../../../../gql/typings';
import { PermissionInfo } from '../../../../../permission/permissionUtils';
import UserSelector from '../../../../../components/UserSelector';
import { TERRITORY_SITE_RESPONSIBLE_QUERY } from './AdminSiteBrickTerritoryAmountOfSites';

type AdminSiteBrickTerritoryResponsibleUserProps = {
  territory: NonNullable<SiteBrickTerritoryQueryQuery['territories']>['nodes']['0'];
  permission: PermissionInfo;
};

const AdminSiteBrickTerritoryResponsibleUser: React.FC<AdminSiteBrickTerritoryResponsibleUserProps> = ({
  territory,
  permission,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [
    update,
    { loading },
  ] = useMutation<UpdateTerritoryResponsibleMutation, UpdateTerritoryResponsibleMutationVariables>(UPDATE_RESPONSIBLE_QUERY);

  return (
    <>
      <Col span={12}>
        <Typography.Text>
          Responsible User:
        </Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text>
          {!isEditing && (territory.responsibleUser?.fullname ?? 'Unassigned')}

          {isEditing && (
            <UserSelector
              startOpen
              loading={loading}
              value={territory.responsibleUser?.id}
              onChange={userId => update({
                refetchQueries: [TERRITORY_SITE_RESPONSIBLE_QUERY],
                variables: {
                  responsibleUserId: userId,
                  territoryCode: territory.code,
                }
              }).finally(() => setIsEditing(false))}
            />
          )}


          {permission.update && !isEditing && (
            <Button type="link" onClick={() => setIsEditing(true)}>
              <EditOutlined />
            </Button>
          )}
        </Typography.Text>
      </Col>
    </>
  );
};

const UPDATE_RESPONSIBLE_QUERY = gql`
  mutation UpdateTerritoryResponsible($territoryCode: String!, $responsibleUserId: Int) {
    updateTerritoryResponsibleUser(territoryCode: $territoryCode, responsibleUserId: $responsibleUserId) {
      code
      responsibleUserId
      responsibleUser { id }
    }
  }
`;

export default AdminSiteBrickTerritoryResponsibleUser;
