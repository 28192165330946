import { useMutation, useQuery, gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Skeleton, Result, Button } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { useCountDown } from 'ahooks';
import manualActions from './manual';
import { CURRENT_IMPORT_STATE_FRAGMENT } from '../queries/ImportProjectQueries';
import ImportProgress from './progress/ImportProgress';
import { ImportProcessingContext } from './ImportProcessingContext';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  CurrentImportProjectStateQuery,
  CurrentImportProjectStateQueryVariables, ImportProcessStatusEnum,
  StartImportProjectMutation, StartImportProjectMutationVariables
} from '../../../../gql/typings';

const Processing: React.FC<{ projectId: number }> = ({ projectId }) => {
  const intl = useIntl();
  const {
    data,
    loading,
    refetch,
  } = useQuery<CurrentImportProjectStateQuery, CurrentImportProjectStateQueryVariables>(DATA_QUERY, {
    pollInterval: 750,
    variables: { projectId },
  });
  const [startProject] = useMutation<StartImportProjectMutation, StartImportProjectMutationVariables>(
    START_IMPORT_MUTATION,
    { variables: { projectId } },
  );

  if (loading) return <Skeleton loading active />;

  const manualActionType = data?.importProject?.manual?.__typename;

  const continueAutomatic = () => {
    startProject({ variables: { projectId } });
  };

  let Action = manualActions.ImportManualNoAction!;

  switch (data?.importProject?.statusCode) {
    case ImportProcessStatusEnum.FAIL:
    case ImportProcessStatusEnum.STOPPED:
      Action = () => {
        const [targetDate, setTargetDate] = useState<Date>(new Date());
        const [retryCountDown, setRetryTime] = useCountDown({ targetDate });
        const ut = data?.importProject?.ut;
        const waitBeforeAllowRetryInSeconds = 30;

        useEffect(() => {
          if (ut) setTargetDate(moment(ut).add(waitBeforeAllowRetryInSeconds, 'seconds').toDate());
        }, [ut, setRetryTime]);
        return <Result
          status="error"
          title="Import Failed"
          subTitle={data!.importProject!.statusNote || intl.formatMessage(Locale.Text.Something_went_wrong)}
          extra={[
            <Button key="open">
              {intl.formatMessage(Locale.Command.Open_project_details)}
            </Button>,
            <Button
              key="retry"
              type="primary"
              disabled={moment().isBefore(moment(data?.importProject?.ut).add(waitBeforeAllowRetryInSeconds, 'seconds'))}
              onClick={() => startProject({ variables: { projectId } }).then(() => refetch())}
            >
              {retryCountDown > 0 ? intl.formatMessage(Locale.Text.You_can_retry_in_amount_of_time, {
                inAmountOfTime: intl.formatRelativeTime(Math.round(retryCountDown / 1000), 'second', { style: 'long' }),
              }) : intl.formatMessage(Locale.Command.Retry)}
            </Button>,
          ]}
        />;
      };
      break;
    default:
      if (manualActionType && manualActions[manualActionType]) Action = manualActions[manualActionType]!;
      else if (manualActionType === undefined) Action = () => <></>;
      else if (data?.importProject?.statusCode !== ImportProcessStatusEnum.NOT_STARTED) {
        // eslint-disable-next-line no-console
        console.error('No ui created for manualActionType: ', manualActionType);
      }
      break;
  }

  return (
    <div>
      <ImportProcessingContext.Provider
        value={{
          lineId: get(data, 'importProject.manual.lineId'),
          projectId: get(data, 'importProject.manual.projectId'),
          templateProcessId: get(data, 'importProject.manual.templateProcessId'),
          refetch,
        }}
      >
        <ImportProgress loading={loading} data={data} continueAutomatic={continueAutomatic} />
        <Action importProject={data?.importProject} loading={loading} continueAutomatic={continueAutomatic} />
      </ImportProcessingContext.Provider>
    </div>
  );
};

const DATA_QUERY = gql`
  query CurrentImportProjectState($projectId: Int!) {
    importProject(id: $projectId) {
      ...currentImportState
    }
  }
  ${CURRENT_IMPORT_STATE_FRAGMENT}
`;
const START_IMPORT_MUTATION = gql`
  mutation StartImportProject($projectId: Int!) {
    startImportProject(id: $projectId) {
      ...currentImportState
    }
  }
  ${CURRENT_IMPORT_STATE_FRAGMENT}
`;

export default Processing;
