import React, { useContext, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import { drop } from 'lodash';
import { customFieldTypes } from '../../../components/EntityTabs/Fields/fieldTypes/CustomField';
import { ActivityDetailContext } from './ActivityDetailsTypes';
import {
  ActivityDetailsCustomFieldsQuery,
  ActivityDetailsCustomFieldsQueryVariables,
  CustomFieldValueFragmentFragment
} from '../../../../gql/typings';
import {
  CustomField_FRAGMENT,
  CustomFieldProps,
  CustomFieldValue_FRAGMENT
} from '../../../components/CustomField/CustomFieldUtils';
import HeaderWithValue from '../../../components/DisplayFields/HeaderWithValue';

const ActivityDetailsCustomFields: React.FC<{ customFieldSaveState: CustomFieldProps['saveState'] }> = ({
  customFieldSaveState,
}) => {
  const {
    activityId,
    activityType,
  } = useContext(ActivityDetailContext);
  const {
    data,
    refetch,
  } = useQuery<ActivityDetailsCustomFieldsQuery, ActivityDetailsCustomFieldsQueryVariables>(DATA_QUERY, {
    variables: {
      activityId: activityId ?? -1,
      activityTypeCode: activityType?.code ?? '',
      includeValues: !!(activityId && activityType),
    }
  });
  const [toSave, setToSave] = customFieldSaveState;

  useEffect(() => {
    if (activityId && toSave.length > 0) {
      toSave[0]?.(activityId)
        .then(refetch)
        .then(() => setToSave(prev => drop(prev, 1)));
    }
  }, [activityId, toSave, refetch, setToSave]);

  return (
    <Row>
      {data?.activityType?.customFields.nodes?.map(cf => {
        const ToDisplay = customFieldTypes[cf.fieldTypeEnum];
        return (
          <Col key={cf.code} sm={24} md={12}>
            <HeaderWithValue heading={cf.heading}>
              <ToDisplay
                key={cf.code}
                field={cf}
                values={cf.customLinkValues as CustomFieldValueFragmentFragment}
                saveState={customFieldSaveState}
              />
            </HeaderWithValue>
          </Col>
        );
      })}
    </Row>
  );
};

const DATA_QUERY = gql`
  query ActivityDetailsCustomFields(
    $activityTypeCode: String!, 
    $activityId: Int, 
    $includeValues: Boolean!, 
    $countries: [CountryCode!]
  ) {
    activityType(code: $activityTypeCode) {
      code
      customFields {
        hash
        nodes {
          code
          heading
          fieldTypeEnum
          ...CustomFieldFragment
          customLinkValues(recordId: $activityId) {
            hash
            @include(if: $includeValues) ...CustomFieldValueFragment
          }
        }
      }
    }
  }
  ${CustomField_FRAGMENT}
  ${CustomFieldValue_FRAGMENT}
`;

export default ActivityDetailsCustomFields;
