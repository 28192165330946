import React, { useContext, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { message, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import DateTimePicker from '../../../../../components/Date/DateTimePicker';
import { ActivityDetailContext } from '../../ActivityDetailsTypes';
import Icon from '../../../../../components/Icon/Icon';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { UpdateActivityDatesMutation, UpdateActivityDatesMutationVariables } from '../../../../../../gql/typings';

const TimeSpan_Edit: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const intl = useIntl();
  const {
    activityType,
    activityId,
    startDate,
    endDate,
    dispatch,
  } = useContext(ActivityDetailContext);
  const [updateDates] = useMutation<UpdateActivityDatesMutation, UpdateActivityDatesMutationVariables>(UPDATE_ACTIVITY_DATES);
  const [messageApi, contextHolder] = message.useMessage();
  const [manualOverriding, setManualOveriding] = useState<boolean>(false);

  const onSave = () => {
    if (activityId) {
      updateDates({
        variables: {
          input: {
            activityId,
            startDate,
            endDate,
          },
        }
      }).then(() => messageApi.success(intl.formatMessage(Locale.Text.Dates_has_been_updated)));
    }
    onCancel();
  };

  useEffect(() => {
    if (!activityId && !manualOverriding) {
      const newEndDate = dayjs(endDate);
      const toAddStandardTimeLength = activityType?.standardTimeLengthMinutes || 0;
      const newVal = newEndDate.hour(startDate.hour()).minute(startDate.minute())
        .add(toAddStandardTimeLength, 'minute');
      if (!newVal.isSame(endDate)) {
        dispatch({ type: 'updateEndDate', value: newVal });
      }
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [activityType]);

  return (
    <div className="activity-details-time-span-edit-container">
      {contextHolder}
      {intl.formatMessage(Locale.Command.Start)}
      <div>
        <DateTimePicker
          id="test-1"
          valueState={[
            startDate,
            // @ts-ignore
            (newValue: Dayjs|null) => {
              setManualOveriding(true);
              dispatch({ type: 'updateStartDate', value: newValue });
              // start date should not be after end date
              if (newValue && dayjs(newValue).isAfter(endDate)) {
                const newTimeInstance = dayjs(newValue).hour(newValue.get('hour')).minute(newValue.get('minute'));
                dispatch({ type: 'updateEndDate', value: newTimeInstance });
              }
            },
          ]}
        />
      </div>
      {intl.formatMessage(Locale.Command.End)}
      <div>
        <DateTimePicker
          id="test-2"
          disableUntil={startDate}
          valueState={[
            endDate,
            // @ts-ignore
            (newValue: Dayjs|null) => {
              setManualOveriding(true);
              if (newValue && dayjs(newValue).isBefore(startDate)) {
                messageApi.error(intl.formatMessage(Locale.Text.Activity_End_Start_Date));
                return;
              }
              dispatch({ type: 'updateEndDate', value: newValue });
            },
          ]}
        />
      </div>
      <div className="actions">
        {activityId && <Tooltip title="Update Activity Date">
          <Icon style={{ fontSize: 22 }} iconType="CHECK_OUTLINED" onClick={onSave} />
        </Tooltip>}
        {activityId && <Tooltip title="Cancel">
          <Icon
            style={{ fontSize: 22, color: 'gray' }}
            iconType="CANCEL_OUTLINED"
            onClick={onCancel}
          />
        </Tooltip>}
      </div>
    </div>
  );
};


const UPDATE_ACTIVITY_DATES = gql`
  mutation UpdateActivityDates($input: UpdateActivityInput!) {
    updateActivity(input: $input) {
      id
      startDate
      endDate
    }
  }
`;


export default TimeSpan_Edit;
