import { Divider, Form, Input, message, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import CustomFieldSelectType from './components/CustomFieldSelectType';
import CreateCustomFieldInputItemField from './CreateCustomFieldInputItemField';
import CustomFieldConfigurationTab from './tabs/CustomFieldConfigurationTab';
import { customFieldTypeOptionSupport } from './customFieldUtils';
import {
  CreateCustomSelectFieldMutationMutation,
  CreateCustomSelectFieldMutationMutationVariables,
  CustomFieldTypeEnum,
  EntityTypeEnum
} from '../../../../gql/typings';

type CreateCustomSelectFieldProps = {
  entityType: EntityTypeEnum;
  initialType?: CustomFieldTypeEnum;
  onCreated?: (field: NonNullable<CreateCustomSelectFieldMutationMutation['createCustomField']>) => void;
};

export type CreateCustomFieldFormData = Record<number, string> & {
  name: string;
  code?: string;
  type: CustomFieldTypeEnum;
  colorConfiguration?: string | null | undefined;
  isActive: boolean;
  allowMultiple?: boolean | null | undefined;
  allowUserCreate?: boolean | null | undefined;
  isCheckableTag?: boolean | null | undefined;
  allowCountrySpecificValues?: boolean | null | undefined;
  options?: {
    id: number;
    value: string | null;
    countries?: string[] | null | undefined;
    count?: number | null;
  }[];
};

const DEFAULT_CUSTOM_FIELD_TYPE = CustomFieldTypeEnum.TEXT;

const CreateCustomField: React.FC<CreateCustomSelectFieldProps> = ({
  entityType,
  initialType,
  onCreated,
}) => {
  const [form] = Form.useForm<CreateCustomFieldFormData>();
  const localization = useLocalization();
  const [
    create,
    { loading: isCreating },
  ] = useMutation<CreateCustomSelectFieldMutationMutation, CreateCustomSelectFieldMutationMutationVariables>(CREATE_MUTATION);

  useEffect(() => {
    form.setFieldsValue({ type: initialType ?? DEFAULT_CUSTOM_FIELD_TYPE });
  }, [form, initialType]);

  const onFinish = () => {
    const {
      name,
      code,
      type,
      options,
      colorConfiguration,
      isCheckableTag,
      isActive,
      allowUserCreate,
      allowMultiple
    } = form.getFieldsValue();
    return create({
      variables: {
        input: {
          code,
          type,
          color: colorConfiguration,
          allowMultiple,
          isCheckableTag,
          allowUserCreate,
          isActive,
          options: customFieldTypeOptionSupport(type) && options
            ? options.map(option => option.value).filter(o => o) as string[]
            : [],
          heading: name,
          entityType: entityType!,
        },
      },
      refetchQueries: ['PersonDetail', 'SiteDetail', 'ActivityDetail', 'HCPMoreData', 'HCOMoreData', 'DefaultCriteriasQuery',
        'availableMassAssignFields'],
    }).then(res => {
      if ((res.errors?.length ?? 0) > 0) {
        message.error(res.errors![0]!.message);
        return;
      }
      if (!res.data?.createCustomField) {
        message.error('Something went wrong while creating custom field. Please contact support');
        return;
      }
      return onCreated?.(res.data.createCustomField);
    });
  };

  const onFinishFailed = (info: ValidateErrorEntity) => {
    const error = info.errorFields[0]?.errors?.[0];
    if (error) return message.error(error);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Typography.Title level={3}>
        {localization.formatMessage(Locale.Text.Create_new_custom_field)}
      </Typography.Title>

      <Divider style={{ marginTop: 8 }} />

      <Row gutter={[0, 12]}>
        <CreateCustomFieldInputItemField
          name="name"
          nameResource={Locale.Attribute.Name}
          tooltipResource={Locale.Attribute.Name}
          rules={[{ required: true, message: localization.formatMessage(Locale.Text.Name_required) }]}
        >
          <Input />
        </CreateCustomFieldInputItemField>
        <CreateCustomFieldInputItemField
          name="type"
          nameResource={Locale.Attribute.Type}
          tooltipResource={Locale.Attribute.Type}
          rules={[{ required: true, message: localization.formatMessage(Locale.Text.Name_required) }]}
        >
          <CustomFieldSelectType isCreated={false} />
        </CreateCustomFieldInputItemField>
      </Row>
      <Divider />
      <Row gutter={[0, 12]}>
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
          {({ getFieldValue }) => <CustomFieldConfigurationTab
            form={form}
            field={null}
            fieldType={getFieldValue('type') ?? DEFAULT_CUSTOM_FIELD_TYPE}
            entityType={entityType}
            loading={isCreating}
          />}
        </Form.Item>
      </Row>
    </Form>
  );
};

const CREATE_MUTATION = gql`
  mutation CreateCustomSelectFieldMutation($input: CreateCustomFieldInput!) {
    createCustomField(input: $input) {
      code
      heading
    }
  }
`;


export default CreateCustomField;
