/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { toNumber } from 'lodash';
import { useMatch, useNavigate } from 'react-router-dom';
import { Affix, Alert, Button, Skeleton, Space } from 'antd';
import { useEventEmitter } from 'ahooks';
import PersonHeaderContainer from '../Components/PersonHeaderContainer';
import { usePageTitle } from '../../../hooks/usePageTitle';
import Puffin from '../../../components/Puffin/Puffin';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import DynamicEntityTabsContainer from '../../../components/EntityTabs/DynamicEntityTabsContainer';
import { PersonAffiliationsTabsEnum } from '../Components/PersonAffiliationsContainer';
import { EntityTypeEnum, PersonDetailQuery, PersonDetailQueryVariables } from '../../../../gql/typings';
import DetailsView from '../../../components/DetailsView/DetailsView';

const PersonDetails = () => {
  const match = useMatch('/hcp/:id');
  const navigate = useNavigate();
  const personId = toNumber(match?.params?.id);
  const localization = useLocalization();
  const activeTabKeyState = useState<PersonAffiliationsTabsEnum>(PersonAffiliationsTabsEnum.activities);
  const { data, loading, refetch } = useQuery<PersonDetailQuery, PersonDetailQueryVariables>(PersonDetails_DATA_QUERY, {
    variables: { personId },
  });
  const emitter = useEventEmitter();

  usePageTitle(data?.person?.fullName);

  useEffect(() => {
    refetch({ personId });
  }, []);


  if (!loading && !data?.person) return (
    <DetailsView className="person-detail-view-content-container">
      <Space>
        <Puffin
          type="crashLand"
          style={{ maxWidth: 800 }}
          title={localization.formatMessage(Locale.Text.Person_not_found)}
          subTitle={localization.formatMessage(Locale.Text.Could_not_find_person_by_id, { personId })}
        >
          <Button onClick={() => navigate('/hcp')}>{localization.formatMessage(Locale.Command.Back_to_search)}</Button>
        </Puffin>
      </Space>
    </DetailsView>
  );
  let hasPrimaryLink = true;
  if (!loading) {
    hasPrimaryLink = data!.person!.affiliations.nodes.filter((aff) => aff.isPrimary).length > 0;
  }

  return (
    <DetailsView className="person-view-container">
      <Skeleton loading={loading}>
        <section style={{ marginLeft: 24 }}>
          {!hasPrimaryLink
            && <Alert
              style={{ marginBottom: 16 }}
              message={localization.formatMessage(Locale.Text.ONE_primary_link_must_point_to_one_active_site)}
              type="warning"
              showIcon
              closable
            />}
          {data?.person?.consentInstructions?.optedOutFromAPB && <Affix offsetTop={70}>
            <Alert
              style={{ marginBottom: 16, position: 'sticky' }}
              message={localization.formatMessage(Locale.Text.Warning_person_opted_out_from_apb)}
              type="error"
              showIcon
            />
          </Affix>}
          <PersonHeaderContainer
            activeTabKeyState={activeTabKeyState}
            personId={personId}
            refreshEmitter={emitter}
          />
        </section>
        <section>
          <DynamicEntityTabsContainer
            recordId={personId}
            countryCode={data?.person?.countryCode}
            entityType={EntityTypeEnum.PERSON}
            relationTabs={{ person: activeTabKeyState }}
            refreshEmitter={emitter}
          />
        </section>
      </Skeleton>
    </DetailsView>
  );
};


export const PersonDetails_DATA_QUERY = gql`
  query PersonDetail($personId: Int!) {
    person(id: $personId) {
      id
      fullName
      isActive
      countryCode
      affiliations {
        hash
        nodes {
          id
          isPrimary
        }

      }
      consentInstructions {
        hash
        optedOutFromAPB
      }
    }
  }
`;


export default PersonDetails;
