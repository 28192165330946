import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Result } from 'antd';
import { NavLink, useMatch } from 'react-router-dom';
import { toNumber } from 'lodash';
import { useEventEmitter } from 'ahooks';
import SiteHeaderContainer from './components/SiteHeaderContainer';
import { usePageTitle } from '../../hooks/usePageTitle';
import Loading from '../../components/Loading/Loading';
import { Locale } from '../../../localization/LocalizationKeys';
import { SiteAffiliationsTabsEnum } from './components/SiteAffiliationContainer';
import DynamicEntityTabsContainer from '../../components/EntityTabs/DynamicEntityTabsContainer';
import { EntityTypeEnum, SiteDetailQuery, SiteDetailQueryVariables } from '../../../gql/typings';
import DetailsView from '../../components/DetailsView/DetailsView';


const SiteDetails: React.FC = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const match = useMatch('/hco/:id');
  const siteId = toNumber(match?.params?.id);
  const activeTabKeyState = useState<SiteAffiliationsTabsEnum>(SiteAffiliationsTabsEnum.personAffiliations);
  const { data, loading } = useQuery<SiteDetailQuery, SiteDetailQueryVariables>(SITE_DATA_QUERY, { variables: { siteId } });
  const emitter = useEventEmitter();

  usePageTitle(data?.site?.name);

  let element;
  if (!data?.site) {
    if (loading) element = <Loading />;
    else element = (
      <Result
        status="404"
        title={formatMessage(Locale.Text.Site_not_found)}
        subTitle={formatMessage(Locale.Text.Site_not_found, { siteId })}
        extra={<NavLink to="/hco">Back</NavLink>}
      />
    );
  } else element = (
    <>
      <section className="site-header-section">
        <SiteHeaderContainer
          siteId={siteId}
          setValue={() => activeTabKeyState[1](SiteAffiliationsTabsEnum.personAffiliations)}
          site={data!.site!}
          loading={loading}
          refreshEmitter={emitter}
        />
      </section>

      <section className="site-tab-section">
        <DynamicEntityTabsContainer
          entityType={EntityTypeEnum.SITE}
          recordId={siteId}
          countryCode={data.site.countryCode}
          relationTabs={{ site: activeTabKeyState }}
          refreshEmitter={emitter}
        />
      </section>
    </>
  );

  return (
    <DetailsView className="site-details-container" form={form}>
      {element}
    </DetailsView>
  );
};

export const SITE_DATA_QUERY = gql`
  query SiteDetail($siteId: Int!) {
    site(siteId: $siteId) {
      id
      name
      isUnplaced
      countryCode
      maintainerSourceCode
      isHcoCherryPicked
      isActive
    }
  }
`;

export default SiteDetails;
