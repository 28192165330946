import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Typography } from 'antd';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { EditOutlined } from '@ant-design/icons';
import {
  BricksForTerritorySelectorQuery,
  BricksForTerritorySelectorQueryVariables, SetTerritoryBricksMutation, SetTerritoryBricksMutationVariables,
  SiteBrickTerritoryQueryQuery
} from '../../../../../../gql/typings';
import TransferComponent from '../../../../../components/TransferModal/TransferComponent';
import { PermissionInfo } from '../../../../../permission/permissionUtils';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { TERRITORY_CARD_QUERY } from './AdminSiteBrickTerritories';
import { TERRITORY_SITE_RESPONSIBLE_QUERY } from './AdminSiteBrickTerritoryAmountOfSites';

type AdminSiteBrickTerritoryAmountOfBricksProps = {
  territory: NonNullable<SiteBrickTerritoryQueryQuery['territories']>['nodes']['0'];
  permission: PermissionInfo;
};

const AdminSiteBrickTerritoryAmountOfBricks: React.FC<AdminSiteBrickTerritoryAmountOfBricksProps> = ({
  territory,
  permission,
}) => {
  const localization = useLocalization();
  const [isEditing, setIsEditing] = useState(false);
  const selectedBrickCodesState = useState<string[]>(territory.bricks.nodes.map(b => b.code));
  const [
    loadBricks,
    { data, loading },
  ] = useLazyQuery<BricksForTerritorySelectorQuery, BricksForTerritorySelectorQueryVariables>(
    BRICKS_QUERY,
    { variables: { territoryCode: territory.code } },
  );
  const [
    setBricks,
    { loading: isSettingBricks },
  ] = useMutation<SetTerritoryBricksMutation, SetTerritoryBricksMutationVariables>(SET_BRICKS_TERRITORY_CODE);

  useEffect(() => {
    if (isEditing && !loading) loadBricks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);


  const onSaveSelectedBricks = () => setBricks({
    refetchQueries: [TERRITORY_CARD_QUERY, TERRITORY_SITE_RESPONSIBLE_QUERY],
    variables: { territoryCode: territory.code, brickCodes: selectedBrickCodesState[0] },
  }).finally(() => setIsEditing(false));

  return (
    <>
      <Col span={12}>
        <Typography.Text>
          Amount of Bricks:
        </Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text>
          {territory.bricks.totalCount}
        </Typography.Text>
        {permission.update && !isEditing && (
          <Button type="link" onClick={() => setIsEditing(true)}>
            <EditOutlined />
          </Button>
        )}
      </Col>

      <Modal
        open={isEditing}
        onCancel={() => setIsEditing(false)}
        width="clamp(35%, 420px, 75%)"
        okText={localization.formatMessage(Locale.Command.Save)}
        cancelText={localization.formatMessage(Locale.Command.Cancel)}
        onOk={onSaveSelectedBricks}
        confirmLoading={isSettingBricks}
      >
        <TransferComponent
          selectedKeys={selectedBrickCodesState}
          dataSource={(data?.bricks.nodes ?? []).map(b => ({ key: b.code, title: b.heading }))}
        />
      </Modal>
    </>
  );
};

const BRICKS_QUERY = gql`
  query BricksForTerritorySelector($territoryCode: String!) {
    bricks(criteria: { territoryCodeOrUnassigned: $territoryCode }) {
      hash
      nodes {
        code
        heading
      }
    }
  }
`;

const SET_BRICKS_TERRITORY_CODE = gql`
  mutation SetTerritoryBricks($territoryCode: String!, $brickCodes: [String!]!) {
    setBricksForTerritory(territoryCode: $territoryCode, brickCodes: $brickCodes) {
      code
    }
  }
`;

export default AdminSiteBrickTerritoryAmountOfBricks;
