
// MARK: The number represents the ID from the database.
import { EntityTypeEnum } from '../../gql/typings';

export const EntityTypeId: Record<EntityTypeEnum, number> = {
  PERSON: 1,
  SITE: 2,
  AFFILIATION: 3,
  ACTIVITY: 4,
  PRODUCT: 5,
  PROJECT: 6,
  IMPORT_PROJECT: 7,
  USER: 8,
  BRAND: 9,
  EDUCATION: 10,
  EXTERNAL_ID: 11,
  DCR: 12,
  BRICK: 13,
  IMPORT_PROCESS_LINE: 14,
  LIST: 15,
  SEGMENTATION_CODE: 16,
  ACTIVITY_TYPE: 17,
  CUSTOM_FIELD: 18,
  PERSON_TYPE: 19,
  ACTIVITY_BRAND: 20,
  PERSON_PROJECT_AFFILIATION: 21,
  POSITION: 22,
  PERSON_ACTIVITY_AFFILIATION: 23,
  DCR_GROUP: 24,
  SITE_TYPE: 25,
  FILTER_CODE: 26,
  PERSON_FILTER_CODE: 27,
  PROJECT_TYPE: 28,
  PROJECT_STATUS: 29,
  PROJECT_ROLE: 30,
  PROJECT_RESPONSE: 31,
  PREFERENCE: 32,
  ACTIVITY_STATUS: 33,
  ACTIVITY_PRODUCT: 34,
  SITE_SPECIALTY: 35,
  SITE_PERSON_WORK_SPECIALTY: 36,
  CUSTOM_LINK_VALUE: 37,
  PURE_ADVANCE_CUSTOMER: 38,
  CONTACT_CATEGORY: 39,
  CONTACT_VALUE: 40,
  ADDRESS_TYPE: 41,
  ADDRESS: 42,
  CUSTOM_VALUE: 43,
  LANGUAGE: 44,
  PERSON_PRODUCT_SEGMENTATION: 45,
  ROLE: 46,
  ENTITY_MERGE: 47,
  AFFILIATION_FROM_PERSON: 48,
  AFFILIATION_FROM_SITE: 49,
  CHAIN: 50,
  CRITERIA: 51,
  EXTERNAL_ID_TYPE: 52,
  SITE_SECTOR_TYPE: 53,
  CHERRY_PICK_OVERVIEW_CUSTOMER: 54,
  PERS_SELECTION_ROW: 55,
  SITE_SELECTION_ROW: 56,
  SELECTION_ROW_RESULT: 57,
  PEDUC: 58,
};
