import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { PermissionEnum } from '../../../../gql/typings';

export const siteTerritoryResponsibleUserFieldConfig = siteFieldConfig(
  'territoryResponsibleUser',
  [
    'id',
    'bricks.hash',
    'bricks.nodes.code',
    'bricks.nodes.territory.code',
    'bricks.nodes.territory.responsibleUser.id',
    'bricks.nodes.territory.responsibleUser.fullname',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Territory_responsible),
    permission: PermissionEnum.TERRITORY,
    additionalTableConfig: {
      width: 100
    },
    render: (site) => site.bricks.nodes[0]?.territory?.responsibleUser?.fullname,
  })
);
