import React from 'react';
import { CustomFieldProps } from './CustomFieldUtils';
import CustomFieldRadio from './CustomFieldRadio';
import CustomFieldCheck from './CustomFieldCheck';


const CustomFieldCheckMultiSupport: React.FC<CustomFieldProps> = props => (
  <span>
    {props.field.allowMultiple
      ? <CustomFieldCheck {...props} />
      : <CustomFieldRadio {...props} />}
  </span>
);


export default CustomFieldCheckMultiSupport;
