import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { PermissionEnum } from '../../../../gql/typings';

export const siteTerritoryFieldConfig = siteFieldConfig(
  'territory',
  [
    'id',
    'bricks.hash',
    'bricks.nodes.code',
    'bricks.nodes.territory.code',
    'bricks.nodes.territory.heading',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Territory),
    permission: PermissionEnum.TERRITORY,
    additionalTableConfig: {
      width: 100
    },
    render: (site) => site.bricks.nodes[0]?.territory?.heading,
  })
);
