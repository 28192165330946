import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  PermissionEnum,
  SelectSiteResSuggestionQueryQuery,
  SelectSiteResSuggestionQueryQueryVariables,
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';

export const siteResponsibleUserFieldConfig = siteFieldConfig(
  'responsibleUser',
  [
    'id',
    'responsibleUser.id',
    'responsibleUser.user.id',
    'responsibleUser.user.fullname',
    'overwrittenResponsibleUser.id',
    'overwrittenResponsibleUser.user.id',
    'overwrittenResponsibleUser.user.fullname',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Responsible_User),
    permission: PermissionEnum.BRICK,
    additionalTableConfig: {
      width: 100
    },
    render: (site) => site.responsibleUser?.user?.fullname,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: true,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select_responsible_User),
      selectedKey: record => record.overwrittenResponsibleUser ? `${record.overwrittenResponsibleUser?.id}` : undefined,
      mutation: SET_SITE_RESPONSIBLE_USER,
      skipDcrWarning: true,
      buildVariables: (selectedKey, record) => ({
        siteId: record.id,
        userId: selectedKey
      }),
      fetchSuggestions: ({ client, userInput }) => (
        client.query<SelectSiteResSuggestionQueryQuery, SelectSiteResSuggestionQueryQueryVariables>({
          query: SUGGESTIONS_QUERY,
          variables: { search: `%${userInput}%` },
        }).then(res => res.data.users.nodes.map(u => ({
          code: `${u.id}`,
          label: u.fullname,
        })))
      ),
    }),
  })
);

const SET_SITE_RESPONSIBLE_USER = gql`
  mutation SetSiteResponsibleUser($siteId: ID!, $userId: ID) {
    setSiteResponsibleUser(siteId: $siteId, userId: $userId) {
      id
      siteId
      site {
        id
        responsibleUser {
          id
          user {
            id
            fullname
          }
        }
      }
    }
  }
`;

const SUGGESTIONS_QUERY = gql`
  query SelectSiteResSuggestionQuery($search: String) {
    users(criteria: { search: $search, fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        id
        fullname
      }
    }
  }
`;
