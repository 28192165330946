import React, { useEffect, useState } from 'react';
import { Tag, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { CustomFieldProps, UPSERT_MUTATION } from './CustomFieldUtils';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import {
  CustomFieldFragmentFragment,
  UpsertCustomFieldValueMutation,
  UpsertCustomFieldValueMutationVariables
} from '../../../gql/typings';


const CustomFieldCheckableTag: React.FC<CustomFieldProps> = ({
  field,
  values,
  saveState: [, save],
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const localization = useLocalization();
  const tagsData = field.options?.nodes ?? [];
  const { CheckableTag } = Tag;
  const [
    upsert,
  ] = useMutation<UpsertCustomFieldValueMutation, UpsertCustomFieldValueMutationVariables>(UPSERT_MUTATION);


  useEffect(() => {
    setSelected(values.nodes.map(it => it.value!.id));
  }, [values]);

  if (!field.accessRules?.allowMutation) {
    return (
      <>
        {tagsData.map(val => (
          <span key={val.value!.id} style={{ margin: 5 }}>
            {val.value!.value}
          </span>
        ))}
      </>
    );
  }

  const onChange = (tag: NonNullable<CustomFieldFragmentFragment['options']>['nodes']['0'], checked: boolean) => {
    if (!field.accessRules?.allowMutation) return;
    if (!tag.value) return;

    const selectedIds = (checked ? field.allowMultiple ? [...selected, tag.value!.id] : [tag.value!.id]
      : selected.filter(val => val !== tag.value!.id)).map(val => ({ valueId: val }));

    setSelected(selectedIds.map(i => i.valueId));

    save(prev => ([
      ...prev,
      recordId => upsert({
        variables: {
          customFieldCode: field.code,
          recordId,
          customValues: selectedIds,
        }
      }),
    ]));
  };

  return (
    <Tooltip
      title={field.accessRules?.allowMutation
        ? '' : localization.formatMessage(Locale.Text.Mutation_permission_denied)}
    >
      {tagsData.map(tag => (
        <CheckableTag
          key={tag.value!.id}
          checked={selected.includes(tag.value!.id)}
          onChange={checked => onChange(tag, checked)}
        >
          {tag.value?.value}
        </CheckableTag>
      ))}
    </Tooltip>
  );
};

export default CustomFieldCheckableTag;

