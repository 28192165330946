import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton, Switch, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { PermissionInfo } from '../../../../permission/permissionUtils';
import {
  InstanceDeploymentProfileQuery,
  InstanceDeploymentSchedulesQueryQueryVariables,
  UpdateInstanceDeploymentBoxSettingMutation, UpdateInstanceDeploymentBoxSettingMutationVariables
} from '../../../../../gql/typings';
import { extractBooleanValue } from '../../../../util/Util';

export type InstanceDeploymentProfileDetailsProps = {
  customerCode: string;
  permission: PermissionInfo;
};

const InstanceDeploymentProfileDetails: React.FC<InstanceDeploymentProfileDetailsProps> = ({ customerCode, permission }) => {
  const {
    loading,
    data,
  } = useQuery<InstanceDeploymentProfileQuery, InstanceDeploymentSchedulesQueryQueryVariables>(DATA_QUERY, {
    variables: { customerCode },
  });
  const [
    setBoxSetting,
    { loading: isUpdatingBoxSetting },
  ] = useMutation<UpdateInstanceDeploymentBoxSettingMutation, UpdateInstanceDeploymentBoxSettingMutationVariables>(
    UPDATE_BOX_SETTING,
  );

  const onChangeBoxSetting = (checked: boolean) => setBoxSetting({
    variables: { customerCode, value: `${checked}` }
  });

  const left= { span: 4 };
  const right = { span: 20 };

  return (
    <Skeleton loading={loading} active={loading}>
      <Row>
        <Col {...left}>Customer Name:</Col>
        <Col {...right}>{data?.pureAdvanceCustomer?.heading}</Col>

        <Col {...left}>
          Download via Box&nbsp;
          {/* eslint-disable-next-line max-len */}
          <Tooltip title="When enabled it will make the users download files through box.com instead of directly from aPureBase servers.">
            <QuestionCircleOutlined />
          </Tooltip>
          :
        </Col>
        <Col {...right}>
          <Switch
            loading={loading || isUpdatingBoxSetting}
            checked={extractBooleanValue(data?.pureAdvanceCustomer?.downloadFilesViaBoxSetting)}
            onChange={onChangeBoxSetting}
            disabled={!permission.update}
          />
        </Col>
      </Row>
    </Skeleton>
  );
};

const UPDATE_BOX_SETTING = gql`
  mutation UpdateInstanceDeploymentBoxSetting($customerCode: String!, $value: String) {
    setPureAdvanceCustomerInstanceSetting(customerCode: $customerCode, setting: DOWNLOAD_FILES_VIA_BOX, value: $value) {
      code
      downloadFilesViaBoxSetting: systemSetting(setting: DOWNLOAD_FILES_VIA_BOX)
    }
  }
`;

const DATA_QUERY = gql`
  query InstanceDeploymentProfile($customerCode: String!) {
    pureAdvanceCustomer(customerCode: $customerCode) {
      code
      heading
      downloadFilesViaBoxSetting: systemSetting(setting: DOWNLOAD_FILES_VIA_BOX)
    }
  }
`;

export default InstanceDeploymentProfileDetails;

