import { useQuery, gql, useMutation } from '@apollo/client';
import React from 'react';
import { Checkbox, Col, Row, Select, Skeleton, Table, Tooltip } from 'antd';
import { get, reduce } from 'lodash';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  DelimiterEnum,
  FileValidationQueryQuery,
  FileValidationQueryQueryVariables, UpdateDelimiterOfFileMutation,
  UpdateDelimiterOfFileMutationVariables
} from '../../../../gql/typings';
import { useLocalization } from '../../../util/useLocalization';


const ValidationOfFile: React.FC<{ projectId: number }> = ({ projectId }) => {
  const localization = useLocalization();

  const { data, loading, refetch } = useQuery<FileValidationQueryQuery, FileValidationQueryQueryVariables>(DATA_QUERY, {
    variables: { projectId },
  });
  const [
    updateDelimiter,
    { loading: isUpdating },
  ] = useMutation<UpdateDelimiterOfFileMutation, UpdateDelimiterOfFileMutationVariables>(UPDATE_DELIMITER);

  if (loading || !data?.importProject || isUpdating) return (
    <Skeleton active loading paragraph={{ rows: 12 }} />
  );

  const lineColumns = data.importProject.linePreview.columns.map((col, index) => ({
    key: get(col, 'code', index),
    dataIndex: index,
    title: (
      <Tooltip title={col && col.note}>
        {get(col, 'label', 'Skipped')}
      </Tooltip>
    ),
  }));


  const lineDataSource = data.importProject.linePreview.values.map(line => reduce(line.values, (acc, value, index) => ({
    ...acc,
    [index]: value.value,
  }), { key: line.key }));

  return (
    <div>
      <Row gutter={48} align="middle" justify="center" style={{ marginBottom: 24 }}>
        <Col span={7}>
          <h2>{localization.formatMessage(Locale.Text.Input_file_preview)}</h2>
        </Col>
        <Col span={6}>
          {localization.formatMessage(Locale.General.Charset)}:
          &nbsp;
          <Select
            value={data.importProject.linePreview.charset}
            disabled
            style={{ width: 180 }}
            options={data.charsets.map(charset => ({
              value: charset,
              label: charset,
            }))}
          />
        </Col>
        <Col span={6}>
          {localization.formatMessage(Locale.Attribute.Delimiter)}:
          &nbsp;
          <Select
            value={data.importProject.linePreview.delimiter}
            onSelect={(delimiter: DelimiterEnum) => updateDelimiter({
              variables: { delimiter, importProjectId: projectId }
            }).then(() => refetch())}
            style={{ width: 180 }}
            options={data.delimiters.map(delimiter => ({
              value: delimiter,
              label: delimiter,
            }))}
          />
        </Col>
        <Col span={5}>
          {localization.formatMessage(Locale.General.First_line_header)}: &nbsp;
          <Checkbox checked={data.importProject.linePreview.firstLineHeader} disabled />
        </Col>
      </Row>
      <Row>
        <Table
          columns={lineColumns}
          dataSource={lineDataSource}
          pagination={false}
          scroll={{ x: true }}
        />
      </Row>
    </div>
  );
};

const DATA_QUERY = gql`
  query FileValidationQuery($projectId:Int!) {
    delimiters
    charsets
    importProject(id: $projectId) {
      id
      linePreview(linesCount: 5) {
        projectId
        charset
        delimiter
        firstLineHeader
        columns {
          code
          label
          note
        }
        values {
          key
          values {
            index
            code
            value
          }
        }
      }
    }
  }
`;

const UPDATE_DELIMITER = gql`
  mutation UpdateDelimiterOfFile($importProjectId: ID!, $delimiter: DelimiterEnum!) {
    updateImportedFileSettings(importProjectId: $importProjectId, delimiter: $delimiter) {
      id
    }
  }
`;

export default ValidationOfFile;
