import React from 'react';
import { Button, Skeleton, Space } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { toNumber } from 'lodash';
import { useMatch, useNavigate } from 'react-router-dom';
import { useEventEmitter } from 'ahooks';
import Puffin from '../../components/Puffin/Puffin';
import LinkHeaderContainer from './Components/LinkHeaderContainer';
import DynamicEntityTabsContainer from '../../components/EntityTabs/DynamicEntityTabsContainer';
import { EntityTypeEnum, LinkDetailQuery, LinkDetailQueryVariables } from '../../../gql/typings';
import DetailsView from '../../components/DetailsView/DetailsView';

const LinkDetails: React.FC = () => {
  const match = useMatch('/link/:id');
  const navigate = useNavigate();
  const linkId = toNumber(match?.params.id);
  const { data, loading } = useQuery<LinkDetailQuery, LinkDetailQueryVariables>(LINK_DATA_QUERY, {
    variables: { linkId },
  });
  const emitter = useEventEmitter();

  if (!loading && !data?.sitePersonAffiliation) return (
    <DetailsView className="link-detail-view-content-container">
      <Space>
        <Puffin
          type="crashLand"
          style={{ maxWidth: 800 }}
          title="Link not found"
          subTitle={`Couldn't find Link by the ID '${linkId}'`}
        >
          <Button onClick={() => navigate('/link')}>Go back to search</Button>
        </Puffin>
      </Space>
    </DetailsView>
  );

  return (
    <DetailsView className="link-detail-view-content-container">
      <Skeleton loading={loading}>
        <section className="person-header-section">
          <LinkHeaderContainer linkId={linkId} />
        </section>
        <section className="person-tab-section">
          <DynamicEntityTabsContainer
            entityType={EntityTypeEnum.AFFILIATION}
            recordId={linkId}
            countryCode={data?.sitePersonAffiliation?.countryCode}
            refreshEmitter={emitter}
          />
        </section>
      </Skeleton>
    </DetailsView>
  );
};

const LINK_DATA_QUERY = gql`
  query LinkDetail($linkId: Int!) {
    sitePersonAffiliation(id: $linkId) {
      id
      apurebaseId
      isActive
      countryCode
      position {
        code
        type
        label
      }
      site {
        id
        name
      }
      person {
        id
        fullName
      }
    }
  }
`;

export default LinkDetails;
