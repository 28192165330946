import React from 'react';
import { DatePicker, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import IsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Optional, StateArray } from '../../util/StateArrayType';
import { DATE_FORMAT } from '../../util/format';


type DateTimePickerProps = {
  id: string;
  valueState: StateArray<Dayjs|null>;
  dateFormat?: string;
  hourFormat?: string;
  disableUntil?: Optional<Dayjs>;
};

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  id,
  valueState: [value, setValue],
  dateFormat = DATE_FORMAT,
  hourFormat = 'HH:mm',
  disableUntil = null,
}) => {
  dayjs.extend(IsSameOrAfter);

  const disabledDate = (current: Dayjs) => {
    if (current) {
      const disableUntilDate = dayjs(disableUntil);
      const plusDays = value?.isSameOrAfter(disableUntil, 'hour') ? 0 : 1;
      disableUntilDate.add(plusDays, 'day');
      return current < disableUntilDate;
    }
    return false;
  };

  // Removing it because when the hour value is before the hour value of start date, some minutes cannot be selected.

  // const disabledTime: TimePickerProps['disabledTime'] = () => {
  //   const disableHour = disableUntil && value?.isSame(disableUntil, 'day');
  //   const disableMinutes = disableUntil && value?.isSame(disableUntil, 'day') && value.isSame(disableUntil, 'hour');
  //   return {
  //     disabledHours: disableHour ? () => range(0, toNumber(disableUntil.format('HH'))): undefined,
  //     disabledMinutes: disableMinutes ? () => range(0, toNumber(disableUntil.format('mm'))) : undefined
  //   };
  // };

  return (<>
    <DatePicker
      id={`${id}-date`}
      value={value}
      format={dateFormat}
      allowClear={false}
      disabledDate={disableUntil ? disabledDate : undefined}
      onChange={m => setValue(m)}
    />
    <TimePicker
      id={`${id}-time`}
      value={value}
      allowClear={false}
      onChange={m => {
        const newTimeInstance = dayjs(m);
        newTimeInstance.hour(m?.get('hour') || 0).minute(m?.get('minute') || 0);
        setValue(newTimeInstance);
      }}
      format={hourFormat}
    />
  </>
  );
};


export default DateTimePicker;
