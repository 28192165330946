import { Col, Form, Radio, Row, Select, Space } from 'antd';
import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useLocalization } from '../../../util/useLocalization';
import { MassAssignFieldTypesProps } from '../MassAssignFieldTypes';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  MassAssignFieldAction,
  MassAssignOptionsQueryQuery,
  MassAssignOptionsQueryQueryVariables
} from '../../../../gql/typings';
import { useSystemCountriesState } from '../../../util/useSystemCountriesState';

const MassAssignSelectType: React.FC<MassAssignFieldTypesProps> = ({
  heading,
  isHeadingKey,
  entityType,
  actionName,
  form,
  valueSelected: [valueSelected, setValueSelected],
  actionSelected: [actionSelected, setActionSelected],
}) => {
  const localization = useLocalization();
  const [search] = useState('');
  const [countries] = useSystemCountriesState();
  const { data, loading } = useQuery<MassAssignOptionsQueryQuery, MassAssignOptionsQueryQueryVariables>(DATA_QUERY, {
    variables: {
      search,
      actionName,
      countries,
    },
  });
  return (
    <div className="mass-assign-container">
      <div className="mass-assign-type-description">
        <p>
          {localization.formatMessage(Locale.Text.Mass_assign_description_selectionType,
            { value: isHeadingKey ? localization.formatMessageByStr(heading) : heading })}
        </p>
      </div>

      <div className="dashboard-card-container">
        <div className="mass-assign-content-container">
          <Form.Item
            name="actionName"
            style={{ display: 'none' }}
            initialValue={actionName}
          />
          <Form.Item
            name="entityType"
            style={{ display: 'none' }}
            initialValue={entityType}

          />
          <Row>
            <Col span={12}>
              <h3>{localization.formatMessage(Locale.Text.Action)}</h3>
              <Form.Item
                name="actionType"
                initialValue={MassAssignFieldAction.SetValueToSelected}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio
                      onClick={() => setActionSelected(MassAssignFieldAction.SetValueToSelected)}
                      value={MassAssignFieldAction.SetValueToSelected}
                    >{localization.formatMessage(Locale.Text.Mass_assign_action_set_value_to_selected)}</Radio>
                    <Radio
                      onClick={() => setActionSelected(MassAssignFieldAction.BlankOutField)}
                      value={MassAssignFieldAction.BlankOutField}
                    >{localization.formatMessage(Locale.Text.Mass_assign_action_set_blank_out)}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

            </Col>
            <Col span={12}>
              <h3>Select value</h3>
              <Form.Item
                name="singleSelectValue"
              >
                <Select
                  disabled={actionSelected === MassAssignFieldAction.BlankOutField}
                  onChange={() => setValueSelected(true)}
                  placeholder="Please select value"
                  style={{ width: '100%' }}
                  showSearch
                  loading={loading}
                  filterOption={(inputValue, option) => option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                  options={(data?.massAssignFieldOptions ?? []).map(option => ({
                    key: option.key,
                    value: option.key,
                    label: option.isHeadingKey ? localization.formatMessageByStr(option.heading) : option.heading,
                  }))}
                />
              </Form.Item>
            </Col>

          </Row>
        </div>

      </div>


    </div>
  );
};
const DATA_QUERY = gql`
  query MassAssignOptionsQuery($actionName: String!, $search: String!, $countries: [CountryCode!]) {
    massAssignFieldOptions(actionName: $actionName, search: $search, countries: $countries) {
      key
      heading
      isHeadingKey
    }
  }
`;


export default MassAssignSelectType;
