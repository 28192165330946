import { Form, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { CreateCustomFieldTypeConfigurationsProps } from '../createCustomFieldTypeConfigurations';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import Icon from '../../../../components/Icon/Icon';


const CreateCustomFieldSelectConfiguration: React.FC<CreateCustomFieldTypeConfigurationsProps> = ({
  field,
}) => {
  const defaultMultipleStatus = field?.allowMultiple || true;
  const defaultCreateStatus = field?.allowUserCreate || false;
  const localization = useLocalization();

  const [defaultStatus, setDefaultStatus] = useState(field?.isActive);
  useEffect(() => {
    if (field) setDefaultStatus(field?.isActive);
  }, [field]);

  return (
    <div>
      <Form.Item
        label={<Icon
          iconType="INFO"
          text={localization.formatMessage(Locale.Text.Allow_multiple)}
          iconBeforeText={false}
          toolTip={localization.formatMessage(Locale.Text.Allow_Multiple_Values_ToolTip)}
        />}
        name="allowMultiple"
        initialValue={defaultMultipleStatus}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        initialValue={defaultCreateStatus}
        label={<Icon
          iconType="INFO"
          text={localization.formatMessage(Locale.Text.Allow_user_create)}
          iconBeforeText={false}
          toolTip={localization.formatMessage(Locale.Text.Allow_Users_ToAdd_Values_ToolTip)}
        />}
        name="allowUserCreate"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label={<Icon
          iconType="INFO"
          text={localization.formatMessage(Locale.General.Status)}
          iconBeforeText={false}
          toolTip={localization.formatMessage(Locale.Text.Custom_Field_Status_ToolTip)}
        />}
        valuePropName="checked"
        name="isActive"
        initialValue={defaultStatus}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name="allowCountrySpecificValues"
        valuePropName="checked"
        label={<Icon
          iconType="INFO"
          text="Allow country specific options"
          iconBeforeText={false}
          toolTip="When enabled, you can choose which countries a specific option should be available in"
        />}
      >
        <Switch />
      </Form.Item>
    </div>
  );
};

export default CreateCustomFieldSelectConfiguration;
