import React, { useState, useEffect } from 'react';
import { useQuery, gql, useApolloClient } from '@apollo/client';
import { Select, Button, Space } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { getRestClient } from '../../../util/rest';
import Icon from '../../../components/Icon/Icon';
import {
  AvailableListFormatsQuery,
  AvailableListFormatsQueryVariables,
  DownloadFileMutation,
  DownloadFileMutationVariables
} from '../../../../gql/typings';
import { useLocalization } from '../../../util/useLocalization';
import { extractBooleanValue } from '../../../util/Util';

type FormatSelectProps = {
  listId: number;
};

const formatToOption = (f: NonNullable<AvailableListFormatsQuery['list']>['availableFormats']['nodes']['0']) => (
  <Select.Option key={f.id} value={f.id}>{f.heading}</Select.Option>
);

const FormatSelect: React.FC<FormatSelectProps> = ({ listId }) => {
  const { data, loading } = useQuery<AvailableListFormatsQuery, AvailableListFormatsQueryVariables>(DATA_QUERY, {
    variables: { listId },
  });
  const apolloClient = useApolloClient();
  const [value, setValue] = useState<number>();
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const localization = useLocalization();

  useEffect(() => {
    if (!value && !loading && data?.list?.availableFormats && data.list.availableFormats.nodes.length > 0) {
      setValue(data.list.availableFormats.nodes[0]!.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const onDownload = (formatId: number) => {
    setIsDownloadingFile(true);

    if (extractBooleanValue(data?.downloadFilesViaBox)) {
      apolloClient.mutate<DownloadFileMutation, DownloadFileMutationVariables>({
        mutation: DOWNLOAD_FILE_MUTATION,
        variables: { listId, listFormatId: formatId },
      }).then(res => {
        if (res.data?.downloadList) {
          window.location.href = res.data.downloadList.url;
        } else return Promise.reject(Error(localization.formatMessage(Locale.Text.Download_failed)));
      }).finally(() => setIsDownloadingFile(false));
    } else {
      getRestClient().post(
        '/download',
        { listId, formatId },
        { responseType: 'blob' },
      ).then(res => {
        const url = URL.createObjectURL(res.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]); // or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }).finally(() => setIsDownloadingFile(false));
    }
  };

  return (
    <div className="import-lists">

      <Button size="middle" type="primary" loading={loading || isDownloadingFile} onClick={() => value && onDownload(value)}>
        <Space>
          <Icon iconType="EXPORT" style={{ fontSize: 18 }} />
          {localization.formatMessage(Locale.Command.Export)}
        </Space>
      </Button>
      <Select
        showSearch
        onChange={setValue}
        value={value}
        placeholder={localization.formatMessage(Locale.Command.Select_list_format)}
        style={{ width: 175 }}
        filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {loading ? null : data?.list?.availableFormats.nodes.map(formatToOption)}
      </Select>
    </div>
  );
};

const DOWNLOAD_FILE_MUTATION = gql`
  mutation DownloadFile($listId: Int!, $listFormatId: Int!) {
    downloadList(listId: $listId, listFormatId: $listFormatId) {
      filename
      url
    }
  }
`;

const DATA_QUERY = gql`
  query AvailableListFormats($listId: Int!) {
    downloadFilesViaBox: instanceSettingValue(type: DOWNLOAD_FILES_VIA_BOX)
    list(id: $listId) {
      id
      affiliations {
        ... on ActivityConnection {
          hash
          totalCount
        }
        ... on SiteConnection {
          hash
          totalCount
        }
        ... on PersonConnection {
          hash
          totalCount
        }
      }
      availableFormats {
        hash
        nodes {
          id
          heading
        }
      }
    }
  }
`;

export default FormatSelect;
