import React from 'react';
import { Col, Tag } from 'antd';
import {
  SiteBrickTerritoryQueryQuery
} from '../../../../../../gql/typings';

type AdminSiteBrickTerritoryBricksOverviewProps = {
  territory: NonNullable<SiteBrickTerritoryQueryQuery['territories']>['nodes']['0'];
};

const AdminSiteBrickTerritoryBricksOverview: React.FC<AdminSiteBrickTerritoryBricksOverviewProps> = ({
  territory,
}) => (
  <Col span={24} style={{ paddingTop: 8 }}>
    {territory.bricks.nodes.map(brick => (
      <Tag key={brick.code}>
        {brick.heading}
      </Tag>
    ))}
  </Col>
);


export default AdminSiteBrickTerritoryBricksOverview;
