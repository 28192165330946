import React from 'react';
import { Col, Divider, Popover, Row, Typography } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { InfoCircleOutlined } from '@ant-design/icons';
import { sortBy } from 'lodash';
import { NavLink } from 'react-router-dom';
import {
  SiteBrickTerritoryQueryQuery,
  TerritorySitesUserResponsibleQuery,
  TerritorySitesUserResponsibleQueryVariables
} from '../../../../../../gql/typings';

type AdminSiteBrickTerritoryAmountOfSitesProps = {
  territory: NonNullable<SiteBrickTerritoryQueryQuery['territories']>['nodes']['0'];
};

const AdminSiteBrickTerritoryAmountOfSites: React.FC<AdminSiteBrickTerritoryAmountOfSitesProps> = ({
  territory,
}) => {
  const { data } = useQuery<TerritorySitesUserResponsibleQuery, TerritorySitesUserResponsibleQueryVariables>(
    TERRITORY_SITE_RESPONSIBLE_QUERY,
    { variables: { territoryCode: territory.code } },
  );

  return (
    <>
      <Col span={12}>
        <Typography.Text>
          Amount of HCOs:
        </Typography.Text>
      </Col>
      <Col span={12}>
        <Typography.Text>
          {territory.bricks.siteTotalCount}
          &nbsp;
          {(data?.users.nodes.length ?? 0) > 1 && (
            <Popover
              title="Responsible users"
              content={
                <div>
                  <Typography.Text>
                    Because it's possible to manually overwrite responsible users of a site.<br />
                    You'll get an overview here of how these {territory.bricks.siteTotalCount} HCOs are shared.
                  </Typography.Text>
                  <Divider />
                  <Row>
                    {sortBy(data?.users.nodes ?? [], ['sitesResponsibleFor', 'totalCount']).map(user => (
                      <React.Fragment key={user.id}>
                        <Col span={12}>
                          {user.fullname}:
                        </Col>
                        <Col span={12}>
                          <NavLink to={`/hco?APB_SITE_RES=${user.id}&APB_TERRITORY=${territory.code}`}>
                            {user.sitesResponsibleFor.totalCount} Sites
                          </NavLink>
                        </Col>
                      </React.Fragment>
                    ))}
                  </Row>
                </div>
              }
            >
              <InfoCircleOutlined />
            </Popover>
          )}
        </Typography.Text>
      </Col>
    </>
  );
};

export const TERRITORY_SITE_RESPONSIBLE_QUERY = gql`
  query TerritorySitesUserResponsible($territoryCode: String!) {
    users(criteria: { responsibleForSitesInTerritoryCode: $territoryCode, isHidden: null, isLocked: null }) {
      hash
      nodes {
        id
        fullname
        sitesResponsibleFor(criteria: { territoryCode: $territoryCode }) {
          hash
          totalCount
        }
      }
    }
  }
`;

export default AdminSiteBrickTerritoryAmountOfSites;
