import React, { useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Input, message, Popover } from 'antd';
import { useDebounceEffect } from 'ahooks';
import { WarningTwoTone } from '@ant-design/icons';
import { Locale } from '../../../../localization/LocalizationKeys';
import { SupportedEntitySearchTypes } from '../../search_old/types';
import { useUser } from '../../../util/useUser';
import { genericFieldConfig, getVariableField } from '../../person/fields/utils/personFieldsUtils';
import {
  DuplicateExternalIDQueryQuery, DuplicateExternalIDQueryQueryVariables,
  ExternalIdControlQuery, ExternalIdControlQueryVariables,
  ExternalIdRuleQueryQuery,
  ExternalIdRuleQueryQueryVariables,
  ExternalIdTypesForFieldQueryQuery,
  ExternalIdTypesForFieldQueryQueryVariables, RemoveExternalIdValueMutation, RemoveExternalIdValueMutationVariables, SourceEnum,
  UpdateExternalIdValueMutation,
  UpdateExternalIdValueMutationVariables,
} from '../../../../gql/typings';
import { usePersonFieldMutation } from '../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import Loading from '../../../components/Loading/Loading';

export const buildEntityExternalIdFieldConfigs = (
  entityType: SupportedEntitySearchTypes,
) => genericFieldConfig(
  'externalId',
  [
    'id',
    'maintainerSourceCode',
    'externalIds(criteria: { externalIdTypeCode: "{{keyCode}}" }).hash',
    'externalIds(criteria: { externalIdTypeCode: "{{keyCode}}" }).nodes.id',
    'externalIds(criteria: { externalIdTypeCode: "{{keyCode}}" }).nodes.externalId',
    'externalIds(criteria: { externalIdTypeCode: "{{keyCode}}" }).nodes.type.id',
    'externalIds(criteria: { externalIdTypeCode: "{{keyCode}}" }).nodes.type.controlSetting.id',
    'externalIds(criteria: { externalIdTypeCode: "{{keyCode}}" }).nodes.type.controlSetting.createDcr',
  ],
  ({ localization }) => ({
    title: ({ selectedOption }) => selectedOption?.label,
    additionalTableConfig: {
      width: 150
    },
    dcrInfo: (record, { selectedOption }) => {
      // MARK: Currently there is only DCR support for the Official ID
      if (record.maintainerSourceCode !== SourceEnum.APB || selectedOption?.code !== 'APB_OFFICIAL') return null;
      return {
        entityType,
        entityAffiliationId: record.id,
        field: Locale.Attribute.Official_ID,
      };
    },
    getOptions: [
      localization.formatMessage(Locale.Attribute.External_ID),
      ({ apolloClient }) => apolloClient.query<ExternalIdTypesForFieldQueryQuery, ExternalIdTypesForFieldQueryQueryVariables>({
        query: EXTERNAL_ID_TYPES_QUERY,
        variables: { entityType },
      }).then(res => res.data.externalIdTypes.nodes.map(n => ({
        code: n.code,
        label: n.isHeadingKey
          ? localization.formatMessageByStr(n.heading)
          : `${n.heading} ${localization.formatMessage(Locale.Attribute.ID)}`,
      }))),
    ],
    render: (record, options) => {
      if (!options.selectedOption) return ' ';
      return ` ${getVariableField(record, options, 'externalIds')
        .nodes
        .map((n) => n.externalId)
        .join(', ')}`;
    },
    hasUpdateSupport: ({ options: { selectedOption } }) => {
      const user = useUser();
      const { data } = useQuery<ExternalIdRuleQueryQuery, ExternalIdRuleQueryQueryVariables>(RULE_QUERY, {
        skip: !selectedOption,
        fetchPolicy: 'cache-first',
        variables: {
          typeCode: (selectedOption?.code ?? '') as string,
          roleId: user.roleId,
        },
      });
      if (!selectedOption) return false;
      return data?.externalIdTypeRoles?.nodes?.[0]?.type?.rules?.allowMutation ?? false;
    },
    updateView: ({ endEditing, options, record }) => {
      if (!options.selectedOption) return <span>Couldn't figure out which id type it was.</span>;
      const { nodes } = getVariableField(record, options, 'externalIds');
      if (nodes.length > 1) return <span>Updating multiple not supported.</span>;
      const node = nodes[0];
      const isDelete = false;
      const { data } = useQuery<ExternalIdControlQuery, ExternalIdControlQueryVariables>(CONTROL_QUERY, {
        variables: { externalIdTypeCode: options.selectedOption.code as string },
      });

      const [checkDupExternalId, { data: dupExIdData, loading: dupExIdLoading }] = useLazyQuery<DuplicateExternalIDQueryQuery
      , DuplicateExternalIDQueryQueryVariables>(DUP_EXTERNAL_ID_QUERY);
      const [inputValue, setInputValue] = useState(node?.externalId ?? '');

      useDebounceEffect(() => {
        if (data?.externalIdType && inputValue) {
          checkDupExternalId({
            variables: {
              typeId: data.externalIdType.id,
              entityAffiliationId: record.id,
              externalId: inputValue
            }
          });
        }
      }, [inputValue], { wait: 200 });

      const {
        Renderer,
        blocking,
        loading,
        submit,
      } = usePersonFieldMutation<UpdateExternalIdValueMutation, UpdateExternalIdValueMutationVariables>({
        mutation: UPDATE_MUTATION,
        controlSetting: data?.externalIdType?.controlSetting,
        skipDcrWarning: record.maintainerSourceCode !== SourceEnum.APB || options.selectedOption.code !== 'APB_OFFICIAL',
        endEditing
      });

      const {
        loading: deleteLoading,
        submit: deleteSubmit,
      } = usePersonFieldMutation<RemoveExternalIdValueMutation, RemoveExternalIdValueMutationVariables>({
        mutation: REMOVE_MUTATION,
        controlSetting: data?.externalIdType?.controlSetting,
        skipDcrWarning: record.maintainerSourceCode !== SourceEnum.APB || options.selectedOption.code !== 'APB_OFFICIAL',
        endEditing
      });


      const onUpdate = () => submit({
        input: {
          internalId: record.id,
          externalIdTypeCode: options.selectedOption!.code as string,
          externalId: inputValue,
        }
      }).then(() => endEditing());

      const onDelete = () => deleteSubmit({
        input: node!.id
      }).then(() => {
        endEditing();
        message.success(localization.formatMessage(Locale.Text.External_ID_has_been_deleted));
      });

      const onBlur = () => !blocking && !isDelete && endEditing();

      return (
        <Renderer>
          <div style={{ position: 'relative' }}>
            {!loading && !deleteLoading
              && <Input
                autoFocus
                allowClear
                value={inputValue}
                defaultValue={node?.externalId}
                onBlur={onBlur}
                status={!dupExIdLoading && dupExIdData
                && !dupExIdData.validateExternalId.isOk ? 'warning' : ''}
                onPressEnter={onUpdate}
                onChange={(e) => {
                  const input = e.target.value;
                  setInputValue(input);
                  if (input === '') {
                    onDelete();
                  }
                }}
              />}
            {(loading || deleteLoading) && <Loading spinSize='small' />}
            <Popover
              content={<span><WarningTwoTone /> {localization.formatMessage(Locale.Text.External_id_already_exists)}</span>}
              open={!dupExIdLoading && dupExIdData
                && !dupExIdData.validateExternalId.isOk}
              placement="right"
            />
          </div>

        </Renderer>
      );
    }
  })
);

const EXTERNAL_ID_TYPES_QUERY = gql`
  query ExternalIdTypesForFieldQuery($entityType: EntityTypeEnum!) {
    externalIdTypes(criteria: { entityType: $entityType }) {
      hash
      nodes {
        code
        heading
        isHeadingKey
        id
      }
    }
  }
`;

const RULE_QUERY = gql`
  query ExternalIdRuleQuery($typeCode: String!, $roleId: ID!) {
    externalIdTypeRoles(criteria: { typeCode: $typeCode, roleId: $roleId }) {
      hash
      nodes {
        id
        type {
          code
          controlSetting {
            id
            createDcr
          }
          rules {
            key
            allowRead
            allowMutation
          }
        }
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateExternalIdValue($input: ExternalIdMutationInput!) {
    setExternalId(input: $input) { id }
  }
`;

const REMOVE_MUTATION = gql`
  mutation RemoveExternalIdValue($input: Int!) {
    removeExternalId(externalIdId: $input) { id }
  }
`;

const CONTROL_QUERY = gql`
  query ExternalIdControl($externalIdTypeCode: String!) {
    externalIdType(code: $externalIdTypeCode) {
      id
      code
      controlSetting {
        id
        createDcr
      }
    }
  }
`;

const DUP_EXTERNAL_ID_QUERY = gql`
  query DuplicateExternalIDQuery($typeId: ID!, $entityAffiliationId: ID!, $externalId: String!) {
    validateExternalId(typeId: $typeId, entityAffiliationId: $entityAffiliationId, externalId: $externalId) {
      errorMessage
      isOk
    }
  }
`;
