import React, { useEffect, useMemo, useState } from 'react';
import { toNumber } from 'lodash';
import { useMatch, useLocation } from 'react-router-dom';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { Col, Row, Space } from 'antd';
import MainView from '../../../components/View/MainView';
import { isActualNumber } from '../../../util/Util';
import ActivityDetailsTopComponents from './Top/ActivityDetailsTopComponents';
import ActivityNotFound from './NotFound/ActivityNotFound';
import { ActivityDetailContext, ActivityDetailsInitialState, ActivityTypeProp } from './ActivityDetailsTypes';
import { ActivityDetailsStateDataOnly, useActivityReducer } from './activityReducer';
import ActivityResponsibleUsers from './ActivityResponsibleUsers/ActivityResponsibleUsers';
import ActivityNoteContainer from './ActivityNoteContainer';
import ActivityProductContainer from './Product/ActivityProductContainer';
import ActivityPersonsDetails from './ActivityPersons/ActivityPersonsDetails';
import ActivityLocationContainer from './ActivitySite/ActivityLocationContainer';
import {
  ActivityDetailsQueryQuery,
  ActivityDetailsQueryQueryVariables,
  ActivityTypesQueryQuery,
  CreateActivityMutationMutation
} from '../../../../gql/typings';
import ActivityType from '../components/ActivityType';
import ActivityStatus from '../components/ActivityStatus';
import ActivityTimeStamps from '../components/ActivityTimeStamps';
import ActivityCreatedBy from '../components/ActivityCreatedBy';
import ActivityDetailsDateTimeSpan from './DateTimeSpan/ActivityDetailsDateTimeSpan';
import ActivityDetailsCustomFields from './ActivityDetailsCustomFields';


type ActivityDetailsProps = {
  activityId?: number;
  skipMainView?: boolean;
  initialState?: ActivityDetailsInitialState;
  onCreated?: (activity: NonNullable<CreateActivityMutationMutation['createActivity']>) => void;
  setVisualState?: () => void;
};

const ActivityDetails: React.FC<ActivityDetailsProps> = ({
  skipMainView,
  initialState: initialStateProps,
  onCreated,
  setVisualState,
  activityId: activityIdProp,
}) => {
  const match = useMatch('/activity/:id');
  const location = useLocation();
  const activityId = isActualNumber(activityIdProp || match?.params?.id) ? toNumber(activityIdProp || match!.params.id) : null;
  const { data: typeData } = useQuery<ActivityTypesQueryQuery>(TypeQuery);

  const { data } = useQuery<ActivityDetailsQueryQuery, ActivityDetailsQueryQueryVariables>(DataQuery, {
    skip: !activityId,
    variables: {
      activityId: activityId ?? -1,
    },
  });


  const initialState = useMemo(() => ({
    ...initialStateProps,
    ...(location.state as { personIds?: number[]; siteId?: number }),
    activityId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [activityId]);

  const [state, errors, dispatch] = useActivityReducer(initialState);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customFieldSaveState = useState<((recordId: number) => Promise<any>)[]>([]);

  useEffect(() => {
    dispatch({ type: 'reset', initial: initialState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);

  useEffect(() => {
    // We are only resetting the state when we navigate to '/create',
    // as all other scenarios the subcomponents will handle their state their selves
    if ((match?.pathname?.indexOf('/create') ?? 0) > -1) dispatch({
      type: 'reset',
      initial: initialState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.pathname]);


  const activityTypes: Record<string, ActivityTypeProp> = useMemo(() => (typeData?.activityTypes.nodes ?? [])
    .reduce((acc, curr) => ({
      ...acc,
      [curr.code]: curr,
    }), {}),
  [typeData]);

  useEffect(() => {
    if (data?.activity?.typeCode) dispatch({ type: 'updateType', value: activityTypes[data.activity.typeCode] });
  }, [activityTypes, data, dispatch]);

  if (activityId && data && !data.activity) return <ActivityNotFound activityId={activityId} />;

  if (!state.show) return <></>;
  const internals = (
    <ActivityDetailContext.Provider
      value={{
        activityId,
        activityTypes,
        editAccess: true,
        ...state,
        allErrors: errors,
        dispatch,
        ...!errors ? {} : (Object.keys(errors) as Array<keyof ActivityDetailsStateDataOnly>)
          .reduce((acc, curr) => ({ ...acc, [`${curr}Errors`]: errors[curr] }), {}),
      }}
    >
      <div className="detail-content" style={{ width: '100%', maxWidth: '1200px' }}>
        <Space direction='vertical' size='large' style={{ width: '100%' }}>
          <ActivityDetailsTopComponents
            setVisualState={setVisualState}
            onCreated={onCreated}
            customFieldSaveState={customFieldSaveState}
          />
          <Row gutter={[25, 0]}>
            <Col lg={12} md={24}>
              <Row align='top' gutter={[10, 0]}>
                <Col sm={24} md={12}>
                  <ActivityType />
                </Col>
                <Col sm={24} md={12}>
                  <ActivityStatus />
                </Col>
                <Col sm={24} md={12}>
                  <ActivityTimeStamps />
                </Col>
                <Col sm={24} md={12}>
                  <ActivityCreatedBy />
                </Col>
                <Col span={24}>
                  <ActivityResponsibleUsers />
                </Col>
                <Col span={24}>
                  <ActivityLocationContainer />
                </Col>
                <Col span={24}>
                  <ActivityDetailsDateTimeSpan />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <ActivityDetailsCustomFields customFieldSaveState={customFieldSaveState} />
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={24}>
              <Row>
                <Col span={24}>
                  <ActivityNoteContainer />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <ActivityProductContainer />
                </Col>
              </Row>
            </Col>
          </Row>
        </Space>
        <ActivityPersonsDetails />

        {/*  */}

      </div>
    </ActivityDetailContext.Provider>
  );

  return skipMainView
    ? internals
    : (
      <MainView className="activity-details-container">
        {internals}
      </MainView>
    );
};

const TypeQuery = gql`
  query ActivityTypesQuery {
    activityTypes {
      hash
      nodes {
        code
        heading
        sort
        superTypeCode
        superType {
          code
          heading
        }

        color {
          code
          value
          headingKey
        }

        customFields {
          hash
          nodes {
            code
            heading
            fieldTypeEnum
          }
        }
        
        locationRule
        participationUserRule
        personRule
        projectRule
        responsibleUserRule
        productRule
        brandRule
        standardTimeLengthMinutes
      }
    }
  }
`;

const DataQuery = gql`
  query ActivityDetailsQuery($activityId: Int!) {
    activity(id: $activityId) {
      id
      heading
      typeCode
      startDate
    }
  }
`;


export default ActivityDetails;
