import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Card, Progress, Result, Row, Skeleton, Steps, Tooltip, } from 'antd';
import { round } from 'lodash';
import { statusOptions } from './statusControl';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { CurrentImportProjectStateQuery, ImportProcessStatusEnum } from '../../../../../gql/typings';


const ImportProgress: React.FC<{
  data?: CurrentImportProjectStateQuery | null;
  loading: boolean;
  continueAutomatic: () => void;
}> = ({
  loading,
  // data: { importProject: project },
  data,
  continueAutomatic,
}) => {
  if (loading) {
    return (
      <Skeleton loading active />
    );
  }

  const statusOption = statusOptions[data!.importProject!.statusCode!];
  const intl = useIntl();

  return (
    <div>
      <h1>
        {data!.importProject!.heading} ({data!.importProject!.statusCode})
      </h1>
      <Card>
        <Row>
          <Steps
            current={data!.importProject!.meta.currentStepIndex}
            style={{ paddingBottom: '24px' }}
            items={data?.importProject?.meta.steps.map(m => (
              {
                title: m
              }
            ))}
          />
        </Row>
        <Row style={{ textAlign: 'center' }}>
          {data!.importProject!.statusCode === ImportProcessStatusEnum.NOT_STARTED
            ? <Button type="primary" onClick={continueAutomatic}>{intl.formatMessage(Locale.Command.Start_Processing)}</Button>
            : (
              <>
                <Progress
                  type="line"
                  percent={round((data!.importProject!.meta.percentage ?? 0) * 100, 1)}
                  status={statusOption.progressIndicator}
                  showInfo={false}
                  style={{ flex: 1 }}
                />
                <Tooltip title={`${(data!.importProject!.meta.percentage ?? 0) * 100}%`}>
                  {round((data!.importProject!.meta.percentage ?? 0) * 100, 1)}%
                </Tooltip>
              </>
            )}
        </Row>
      </Card>

      {data!.importProject!.statusCode === ImportProcessStatusEnum.PROCESSED && <Result
        status="success"
        title={intl.formatMessage(Locale.Text.Successfully_imported_file)}
      />}
    </div>
  );
};

export default ImportProgress;
