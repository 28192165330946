import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Tabs } from 'antd';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { useLocalization } from '../../util/useLocalization';
import DynamicEntityTab, { DYNAMIC_ENTITY_TAB_GRID_FRAGMENT } from './DynamicEntityTab/DynamicEntityTab';
import EntityTabsContext from './EntityTabsContextType';
import { LinkAffiliationsTabsEnum } from '../../browse/link/LinkAffiliationTabs';
import { SiteAffiliationsTabsEnum } from '../../browse/site/components/SiteAffiliationContainer';
import {
  PersonAffiliationsTabsEnum,
} from '../../browse/person/Components/PersonAffiliationsContainer';
import { Optional, StateArray } from '../../util/StateArrayType';
import { DynamicEntityTabsQueryQuery, DynamicEntityTabsQueryQueryVariables, EntityTypeEnum } from '../../../gql/typings';

export type DynamicEntityTabsContainerProps = {
  entityType: EntityTypeEnum;
  recordId: number;
  countryCode: Optional<string>;
  refreshEmitter: EventEmitter<void>;
  relationTabs?: {
    person?: StateArray<PersonAffiliationsTabsEnum>;
    site?: StateArray<SiteAffiliationsTabsEnum>;
    link?: StateArray<LinkAffiliationsTabsEnum>;
  };
};

const DynamicEntityTabsContainer: React.FC<DynamicEntityTabsContainerProps> = ({
  entityType,
  recordId,
  countryCode,
  relationTabs,
  refreshEmitter,
}) => {
  const localization = useLocalization();
  const { data } = useQuery<DynamicEntityTabsQueryQuery, DynamicEntityTabsQueryQueryVariables>(DATA_QUERY, {
    variables: { entityType },
  });
  const personRelationsState = useState<PersonAffiliationsTabsEnum>(PersonAffiliationsTabsEnum.activities);
  const siteRelationsState = useState<SiteAffiliationsTabsEnum>(SiteAffiliationsTabsEnum.personAffiliations);
  const linkRelationsState = useState<LinkAffiliationsTabsEnum>(LinkAffiliationsTabsEnum.personAffiliations);

  return (
    <div className="dynamic-entity-tabs-container">
      <EntityTabsContext.Provider
        value={{
          relationTabs: {
            person: relationTabs?.person ?? personRelationsState,
            site: relationTabs?.site ?? siteRelationsState,
            link: relationTabs?.link ?? linkRelationsState,
          }
        }}
      >
        <Tabs
          type="card"
          className="dynamic-tabs"
          items={(data?.entityTabs.nodes ?? []).map(tab => ({
            key: tab.code,
            label: localization.formatMessageByStr(tab.headingKey),
            children: <DynamicEntityTab
              entityType={entityType}
              recordId={recordId}
              countryCode={countryCode}
              items={tab.gridItems.nodes}
              refreshEmitter={refreshEmitter}
            />
          }))}
        />
      </EntityTabsContext.Provider>
    </div>
  );
};

const DATA_QUERY = gql`
  query DynamicEntityTabsQuery($entityType: EntityTypeEnum!) {
    entityTabs(criteria: { entityType: $entityType }) {
      hash
      nodes {
        code
        headingKey
        gridItems {
          hash
          nodes {
            id
            ...DynamicTabGridFragment
          }
        }
      }
    }
  }
  ${DYNAMIC_ENTITY_TAB_GRID_FRAGMENT}
`;

export default DynamicEntityTabsContainer;
