import { DocumentNode, useMutation } from '@apollo/client';
import React, { useState, useRef, useCallback } from 'react';
import { Button, Form, Modal, Popconfirm, Typography } from 'antd';
import { FormInstance, FormProps } from 'antd/lib/form/Form';
import { Optional } from '../../../../../util/StateArrayType';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { DCR_POPOVER_QUERY } from '../../../../../components/DcrPopOver/DcrPopOver';

type RendererProps<V> = {
  heading?: string;
  variables?: V;
  onClose?: () => void;
  form?: FormInstance<V>;
  formProps?: FormProps;
  width?: string;
};


// TODO: As this has been generalized, it should be renamed and moved out from the [person] components
export function usePersonFieldMutation<T, V>(args: {
  mutation: DocumentNode;
  refetch?: () => void;
  asModal?: boolean;
  endEditing?: () => void;
  controlSetting: Optional<{ id: number; createDcr: boolean }>;
  skipDcrWarning?: boolean;
}): {
    Renderer: React.FC<RendererProps<V>>;
    blocking: boolean;
    submit: (variables: V) => Promise<T>;
    loading: boolean;
  } {
  const localization = useLocalization();
  const [updateMutation, { loading }] = useMutation<T, V>(args.mutation);
  const [vars, setVars] = useState<V>();
  const [blocking, setBlocking] = useState(false);
  const resolve = useRef<(value: T) => void>();
  const reject = useRef<() => void>();

  const submitDcr = () => updateMutation({ variables: vars, refetchQueries: [DCR_POPOVER_QUERY] })
    .then(res => {
      if (res.data) resolve.current?.(res.data);
      else reject.current?.();
    })
    .catch(reject.current)
    .finally(() => {
      args.refetch?.();
      setVars(undefined);
    });

  const showDcrWarning = args.skipDcrWarning ? false : args.controlSetting?.createDcr;

  const Renderer = useCallback(({
    children,
    heading,
    onClose,
    variables,
    formProps,
    form,
    width,
  }: { children?: React.ReactNode } & RendererProps<V>) => {
    const onCancel = () => {
      setBlocking(false);
      setVars(undefined);
      args.endEditing && args.endEditing();
      onClose?.();
    };
    if (!args.asModal) return (
      <>
        {form
          ? <Form form={form} {...(formProps || {})}>{children}</Form>
          : children}
        <Modal
          title={localization.formatMessage(Locale.Command.Create_DCR)}
          open={!!vars}
          onOk={submitDcr}
          okText={localization.formatMessage(Locale.Command.Save)}
          onCancel={onCancel}
          confirmLoading={loading}
          width={width}
        >
          <Typography.Paragraph>
            {localization.formatMessage(Locale.Text.Create_dcr_verification)}
          </Typography.Paragraph>
        </Modal>
      </>
    );
    if (!variables && !form) {
      throw Error("When using the modal version it is required to have 'variables' or 'form' defined as a prop!");
    }
    return (
      <Modal
        title={heading}
        open
        onCancel={onCancel}
        confirmLoading={loading}
        width={width}
        footer={[
          <Button key="1" onClick={onCancel}>
            {localization.formatMessage(Locale.Command.Cancel)}
          </Button>,
          showDcrWarning ? <Popconfirm
            key="2"
            title={localization.formatMessage(Locale.Text.Create_dcr_verification)}
            onConfirm={() => updateMutation({ variables, refetchQueries: [DCR_POPOVER_QUERY] }).finally(() => {
              args.refetch?.();
              onClose?.();
            })}
          >
            <Button type="primary" loading={loading} htmlType="submit">
              {localization.formatMessage(Locale.Command.Save)}
            </Button>
          </Popconfirm> : <Button
            key="2"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={() => {
              const doUpdate = (values?: V) => updateMutation({
                variables: values,
                refetchQueries: [DCR_POPOVER_QUERY],
              }).finally(() => { args.refetch?.(); onClose?.(); });

              if (form) form.validateFields().then(doUpdate);
              else doUpdate(variables);
            }}
          >
            {localization.formatMessage(Locale.Command.Save)}
          </Button>,
        ]}
      >
        {form
          ? <Form form={form} {...(formProps || {})}>{children}</Form>
          : children}
      </Modal>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vars, loading]);

  return {
    Renderer,
    blocking, // MARK: If possible, we would like some solution that does not require managing this [blocking] state.
    loading,
    submit: (variables: V) => {
      // The Promise needs to be defined before the [updateMutation] is called,
      // so we can call the resolve/reject within the updateMutation promise.
      const p = new Promise<T>((res, rej) => { resolve.current = res; reject.current = rej; });
      setBlocking(true);

      if (showDcrWarning) setVars(variables);
      else updateMutation({ variables, refetchQueries: [DCR_POPOVER_QUERY] })
        .then(res => {
          if (res.data) resolve.current?.(res.data);
          else reject.current?.();
        })
        .catch(reject.current)
        .finally(() => {
          args.refetch?.();
          setVars(undefined);
          setBlocking(false);
        });

      return p;
    },
  };
}
