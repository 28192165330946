import React from 'react';
import { Badge, Button, Form, Input, Popover, Tooltip, Typography } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { min } from 'lodash';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { CreateCustomFieldTypeSectionProps } from './createCustomFieldTypes';
import { useLocalization } from '../../../../util/useLocalization';
import { draggableFormTable } from '../../../../components/DraggableTable/DraggableFormTable';
import FlagSelector from '../../../../components/Flag/FlagSelector';

const CreateCustomFieldOptionsSection: React.FC<CreateCustomFieldTypeSectionProps> = ({
  customFieldCode,
  form,
  children,
}) => {
  const localization = useLocalization();

  return (
    <>
      <Typography.Text>
        {localization.formatMessage(Locale.Text.Create_new_custom_field_information)}
      </Typography.Text>
      <Form.List name="options">
        {draggableFormTable({
          localization,
          pagination: false,
          showHeader: false,
          columns: [
            {
              dataIndex: 'value',
              render: (_, field) => (
                <div>
                  <Form.Item
                    noStyle
                    name={[field.name, 'value']}
                    rules={[{ required: true, message: localization.formatMessage(Locale.Text.Options_cant_be_blank) }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) => prev.allowCountrySpecificValues != next.allowCountrySpecificValues}
                  >
                    {({ getFieldValue }) => (
                      <Form.Item
                        hidden={!getFieldValue('allowCountrySpecificValues')}
                        name={[field.name, 'countries']}
                        valuePropName="selected"
                        noStyle
                        style={{ paddingTop: 4 }}
                        rules={[
                          {
                            type: 'array',
                            required: getFieldValue('allowCountrySpecificValues'),
                            min: getFieldValue('allowCountrySpecificValues') ? 1 : 0,
                            message: 'You must provide at least 1 country for each option!',
                          }
                        ]}
                      >
                        <FlagSelector rectangle />
                      </Form.Item>
                    )}
                  </Form.Item>
                </div>
              ),
            },
            {
              hide: !customFieldCode,
              render: (_, field) => (
                <Form.Item
                  noStyle
                  name={[field.name, 'count']}
                  valuePropName="count"
                >
                  <Badge />
                </Form.Item>
              )
            },
            {
              render: (_, field, index) => (
                <>
                  <Form.Item name={[field.name, 'id']} noStyle>
                    <Input hidden />
                  </Form.Item>
                  <Form.Item shouldUpdate={(prev, next) => prev.count !== next.count} noStyle>
                    {({ getFieldValue }) => <Popover
                      title={
                        <div style={{ margin: 8 }}>
                          {localization.formatMessage(Locale.Text.Are_you_sure_to_remove_option)}
                        </div>
                      }
                      trigger={['click']}
                      content={<div style={{ display: 'flex', gap: 8, flexDirection: 'row-reverse' }}>
                        <Tooltip
                          title={getFieldValue('options')[index].count > 0
                            ? 'Cant delete this record, because it has been assigned to other records'
                            : 'Deleting this record will permanently remove it from the custom field'}
                        >
                          <Button
                            size="small"
                            disabled={getFieldValue('options')[index].count > 0}
                            danger
                            type="primary"
                            // @ts-ignore
                            onClick={() => field.operation.remove(index)}
                          >
                            {localization.formatMessage(Locale.Command.Yes_remove)}
                          </Button>
                        </Tooltip>
                      </div>}
                    >
                      <MinusCircleOutlined style={{ cursor: 'pointer' }} />
                    </Popover>}
                  </Form.Item>
                </>
              )
            },
          ]
        })}
      </Form.List>
      <div style={{ display: 'flex', justifyContent: 'center', gap: 12, margin: 12 }}>
        <Button
          onClick={() => {
            const existing: { id: number }[] = form.getFieldValue('options') || [];
            form.setFields([{
              name: 'options',
              value: [
                ...existing,
                {
                  id: (min([0, ...existing.map(e => e.id)]) ?? 0) - 1,
                  value: '',
                },
              ],
            }]);
          }}
        >
          {localization.formatMessage(Locale.Command.Add_option)}
        </Button>
        {children}
      </div>
    </>
  );
};


export default CreateCustomFieldOptionsSection;
