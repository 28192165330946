import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Skeleton, Tooltip } from 'antd';
import Flag, { CountryLabel, FlagCountry } from './Flag';
import { Optional } from '../../util/StateArrayType';
import { useLocalization } from '../../util/useLocalization';
import { AvailableFlagsQueryQuery } from '../../../gql/typings';

type FlagSelectorProps = {
  selected?: Optional<FlagCountry[]>;
  onChange?: (newValues: Optional<FlagCountry[]>) => void;
  rectangle?: boolean;
  square?: boolean;
};

const FlagSelector: React.FC<FlagSelectorProps> = ({ selected, onChange, rectangle, square }) => {
  const localization = useLocalization();
  const { data, loading } = useQuery<AvailableFlagsQueryQuery>(DATA_QUERY);

  const items: Optional<FlagCountry[]> = onChange
    ? (data?.countries.nodes.map(c => c.code as FlagCountry) ?? [])
    : selected ?? [];

  return (
    <Skeleton paragraph={{ rows: 0 }} active={loading} loading={loading}>
      <div style={{ display: 'flex', columnGap: 8, width: '100%', justifyContent: 'space-between' }}>
        <span>
          {items.map(country => (
            <Tooltip key={country} title={localization.formatMessage(CountryLabel[country])}>
              <span
                style={{
                  margin: 4,
                  cursor: onChange ? 'pointer' : 'default',
                  opacity: selected?.includes(country) ? 1 : 0.3,
                }}
                onClick={() => {
                  if (selected?.includes(country)) {
                    onChange?.(selected?.filter(countryCode => countryCode !== country));
                  } else {
                    onChange?.([...(selected ?? []), country]);
                  }
                }}
              >
                <Flag
                  country={country}
                  rectangle={rectangle}
                  square={square}
                />
              </span>
            </Tooltip>
          ))}
        </span>
      </div>
    </Skeleton>
  );
};

const DATA_QUERY = gql`
  query AvailableFlagsQuery {
    countries {
      hash
      nodes {
        code
      }
    }
  }
`;

export default FlagSelector;
