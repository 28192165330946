import React from 'react';
import { Button, Card, message, Row, Typography } from 'antd';
import { gql, useMutation } from '@apollo/client';
import Flag, { FlagCountry } from '../../../../../components/Flag/Flag';
import { useEditable } from '../../../../../util/useEditable';
import {
  InactivateTerritoryMutation, InactivateTerritoryMutationVariables,
  SiteBrickTerritoryQueryQuery,
  UpdateTerritoryHeadingMutationMutation,
  UpdateTerritoryHeadingMutationMutationVariables,
} from '../../../../../../gql/typings';
import AdminSiteBrickTerritoryResponsibleUser from './AdminSiteBrickTerritoryResponsibleUser';
import { PermissionInfo } from '../../../../../permission/permissionUtils';
import AdminSiteBrickTerritoryAmountOfSites from './AdminSiteBrickTerritoryAmountOfSites';
import AdminSiteBrickTerritoryAmountOfBricks from './AdminSiteBrickTerritoryAmountOfBricks';
import AdminSiteBrickTerritoryBricksOverview from './AdminSiteBrickTerritoryBricksOverview';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../../util/useLocalization';
import { TERRITORY_CARD_QUERY } from './AdminSiteBrickTerritories';

type AdminSiteBrickTerritoryCardProps = {
  territory: TerritoryType;
  territoryPermission: PermissionInfo;
  bricksPermission: PermissionInfo;
};

type TerritoryType = NonNullable<SiteBrickTerritoryQueryQuery['territories']>['nodes']['0'];

const AdminSiteBrickTerritoryCard: React.FC<AdminSiteBrickTerritoryCardProps> = ({
  territory,
  territoryPermission,
  bricksPermission,
}) => {
  const localization = useLocalization();
  const [
    updateHeading,
  ] = useMutation<UpdateTerritoryHeadingMutationMutation, UpdateTerritoryHeadingMutationMutationVariables>(UPDATE_HEADING);
  const [
    inactivate,
  ] = useMutation<InactivateTerritoryMutation, InactivateTerritoryMutationVariables>(INACTIVATE_TERRITORY);


  const { editable: headingEditable, isEditing: headingIsEditing } = useEditable<TerritoryType>({
    onSave: (terr, newHeading) => {
      if (terr.heading != newHeading) updateHeading({
        variables: { territoryCode: terr.code, newHeading },
      }).then(res => {
        if (res.errors?.[0]?.message) message.error(res.errors[0].message);
        else message.success('Successfully updated the territory heading');
      });
    },
  });

  return (
    <Card
      title={
        <span>
          {!headingIsEditing && <Flag country={territory.countryCode as FlagCountry} rectangle height={20} />}
          <Typography.Text editable={headingEditable(territory)}>
            {territory.heading}
          </Typography.Text>
        </span>
      }
      extra={territoryPermission.delete && (
        <Button
          type="primary"
          danger
          size="small"
          onClick={() => inactivate({
            refetchQueries: [TERRITORY_CARD_QUERY],
            variables: { territoryCode: territory.code },
          })}
        >
          {localization.formatMessage(Locale.Command.Remove)}
        </Button>
      )}
    >
      <Row>
        <AdminSiteBrickTerritoryResponsibleUser territory={territory} permission={territoryPermission} />
        <AdminSiteBrickTerritoryAmountOfBricks territory={territory} permission={bricksPermission} />
        <AdminSiteBrickTerritoryAmountOfSites territory={territory} />
        <AdminSiteBrickTerritoryBricksOverview territory={territory} />
      </Row>
    </Card>
  );
};


const UPDATE_HEADING = gql`
  mutation UpdateTerritoryHeadingMutation($territoryCode: String!, $newHeading: String!) {
    updateTerritoryHeading(territoryCode: $territoryCode, heading: $newHeading) {
      code
      heading
    }
  }
`;

const INACTIVATE_TERRITORY = gql`
  mutation InactivateTerritory($territoryCode: String!) {
    inactivateTerritory(territoryCode: $territoryCode) {
      code
      isActive
    }
  }
`;


export default AdminSiteBrickTerritoryCard;
