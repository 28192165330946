import { gql } from '@apollo/client';
import { CustomFieldFragmentFragment, CustomFieldValueFragmentFragment } from '../../../gql/typings';
import { StateArray } from '../../util/StateArrayType';

export type CustomFieldProps = {
  field: CustomFieldFragmentFragment;
  values: CustomFieldValueFragmentFragment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveState: StateArray<((recordId: number) => Promise<any>)[]>;
};


export const UPSERT_MUTATION = gql`
  mutation UpsertCustomFieldValue($customFieldCode: String!
    , $customValues: [UpdateSelectCustomFieldValueForRecord!]!,$recordId: Int!) {
    setCustomLinkValues(customFieldCode: $customFieldCode, customValues: $customValues, recordId: $recordId) {
      customFieldCode
    }
  }
`;

export const REMOVE_MUTATION = gql`
  mutation RemoveCustomFieldValue($customValues: [ID!]!) {
    removeCustomLinkValues(customLinkValueIds: $customValues)
  }
`;

export const CustomField_FRAGMENT = gql`
  fragment CustomFieldFragment on CustomField {
    code
    isActive
    allowMultiple
    color
    allowUserCreate
    isCheckableTag
    customFieldControlSetting {
      id
      createDcr
      maintainerSourceCode
      isSynced
    }
    fieldType {
      code
      enum
    }
    options(criteria: { fetchSize: { limit: null }, countries: $countries}) {
      hash
      nodes {
        id
        value {
          id
          value
        }
      }
    }
    accessRules: myAccessRules {
      id
      allowRead
      allowMutation
    }
  }
`;

export const CustomFieldValue_FRAGMENT = gql`
  fragment CustomFieldValueFragment on CustomLinkValueConnection {
    hash
    nodes {
      id
      value {
        id
        value
      }
    }
  }
`;
