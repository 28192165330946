import { useBroadcastStorage } from './useBroadcastStorage';

const TABLE_ROW_SIZE_KEY = 'table-row-size';

export enum TableRowSizeEnum {
  LARGE = 'large-row',
  SMALL = 'small-row'
}

export const useTableRowSize = () => useBroadcastStorage<TableRowSizeEnum>(TABLE_ROW_SIZE_KEY,
  TableRowSizeEnum.SMALL);
