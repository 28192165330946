import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Col, Row, TreeSelect, Typography } from 'antd';
import { NetworkStatus } from '@apollo/client/core';
import { useLocalization } from '../../../../util/useLocalization';
import SortableElements from '../../../../components/SortableElements/SortableElements';
import './_adminConfigureSearch.less';
import { Locale } from '../../../../../localization/LocalizationKeys';
import {
  AdminCriteriaGroupsQueryQuery, AdminCriteriaGroupsQueryQueryVariables,
  ConfigureDefaultCriteriasQueryQuery,
  ConfigureDefaultCriteriasQueryQueryVariables,
  EntityTypeEnum, UpdateDefaultCriteriasMutationMutation, UpdateDefaultCriteriasMutationMutationVariables
} from '../../../../../gql/typings';

type AdminConfigureSearchProps = {
  entityType: EntityTypeEnum;
};

const AdminConfigureSearch: React.FC<AdminConfigureSearchProps> = ({ entityType }) => {
  const localization = useLocalization();
  const options = {
    variables: { entityType },
    notifyOnNetworkStatusChange: true,
  };
  const {
    data,
    networkStatus: dataNetwork,
  } = useQuery<ConfigureDefaultCriteriasQueryQuery, ConfigureDefaultCriteriasQueryQueryVariables>(DATA_QUERY, options);
  const {
    data: groupData,
    networkStatus: groupNetwork,
  } = useQuery<AdminCriteriaGroupsQueryQuery, AdminCriteriaGroupsQueryQueryVariables>(DATA_GROUPS_QUERY, options);
  const [
    updateMutation,
    { loading: isUpdating },
  ] = useMutation<UpdateDefaultCriteriasMutationMutation, UpdateDefaultCriteriasMutationMutationVariables>(UPDATE_MUTATION);

  const loading = isUpdating
    || [NetworkStatus.loading, NetworkStatus.refetch].includes(dataNetwork)
    || [NetworkStatus.loading, NetworkStatus.refetch].includes(groupNetwork);
  const inDataCriteriaIds = (data?.defaultCriterias.nodes.map(dc => dc.criteria.id) ?? []);

  const checkSelected = (criteriaId: number) => inDataCriteriaIds.includes(criteriaId);

  const update = (criteriaIds: number[]) => updateMutation({
    refetchQueries: [DATA_QUERY],
    variables: {
      entityType,
      criteriaIds,
    },
  });

  const onSelect = (criteriaId: number) => {
    if (checkSelected(criteriaId)) update(
      (data?.defaultCriterias.nodes ?? []).map(n => n.criteria.id).filter(cId => cId !== criteriaId)
    ); else update([
      ...(data?.defaultCriterias.nodes ?? []).map(n => n.criteria.id),
      criteriaId,
    ]);
  };

  return (
    <div className="admin-configure-search-container">
      <Row gutter={48}>
        <Col lg={10} md={24}>
          <Typography.Text>
            {localization.formatMessage(Locale.Text.Configure_default_criterias_description)}
          </Typography.Text>
          <SortableElements<NonNullable<ConfigureDefaultCriteriasQueryQuery['defaultCriterias']>['nodes']['0']>
            standardItemDesign={{ size: 'small' }}
            loading={loading}
            rowKey={n => `${n.id}`}
            dataSource={data?.defaultCriterias.nodes ?? []}
            className="sortable-elements"
            onUpdate={rows => update(rows.map(r => r.criteria.id))}
            delayMs={50}
            renderItem={node => (
              <div>
                {localization.formatMessageByStr(node.criteria.heading)}
              </div>
            )}
          />
        </Col>
        <Col lg={10} md={24}>
          <Typography.Text>
            {localization.formatMessage(Locale.Text.Add_criteria_to_default)}
          </Typography.Text>
          <br />
          <TreeSelect
            style={{ width: 240 }}
            showSearch
            loading={loading}
            treeDefaultExpandAll
            treeNodeFilterProp="titleValue"
            onChange={onSelect}
            placeholder={localization.formatMessage(Locale.Command.Add_Criteria)}
            // @ts-ignore
            value={null}
            treeData={(groupData?.criteriaGroups.nodes ?? []).map(group => {
              const groupCount = group.criterias.nodes.length;
              const selectedCount = group.criterias.nodes.reduce((acc, curr) => checkSelected(curr.id) ? acc + 1 : acc, 0);
              return ({
                value: group.code,
                title: `${localization.formatMessageByStr(group.heading)} (${selectedCount}/${groupCount})`,
                titleValue: `${localization.formatMessageByStr(group.heading)} (${selectedCount}/${groupCount})`,
                checkable: false,
                children: group.criterias.nodes.map(criteria => ({
                  value: criteria.id,
                  titleValue: localization.formatMessageByStr(criteria.heading),
                  title: checkSelected(criteria.id)
                    ? <strong>{localization.formatMessageByStr(criteria.heading)}</strong>
                    : localization.formatMessageByStr(criteria.heading),
                })),
              });
            })}
          />
        </Col>
      </Row>
    </div>
  );
};

export const UPDATE_MUTATION = gql`
  mutation UpdateDefaultCriteriasMutation($entityType: EntityTypeEnum!, $criteriaIds: [Int!]!) {
    updateDefaultCriterias(entityType: $entityType, criteriaIds: $criteriaIds) {
      id
      sort
    }
  }
`;

const DATA_GROUPS_QUERY = gql`
  query AdminCriteriaGroupsQuery($entityType: EntityTypeEnum!) {
    criteriaGroups(criteria: { entityType: $entityType }) {
      hash
      nodes {
        code
        entityId
        heading
        criterias(criteria: { fetchSize: { limit: null } }) {
          hash
          nodes {
            id
            entityId
            heading
          }
        }
      }
    }
  }
`;

const DATA_QUERY = gql`
  query ConfigureDefaultCriteriasQuery($entityType: EntityTypeEnum!) {
    defaultCriterias(criteria: { entityType: $entityType }) {
      hash
      nodes {
        id
        sort
        criteria {
          id
          entityId
          heading
        }
      }
    }
  }
`;

export default AdminConfigureSearch;
