import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Input, Row, Space, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CustomFieldProps, REMOVE_MUTATION, UPSERT_MUTATION } from './CustomFieldUtils';
import Icon from '../Icon/Icon';
import {
  UpsertCustomFieldValueMutation, UpsertCustomFieldValueMutationVariables,
  RemoveCustomFieldValueMutation,
  RemoveCustomFieldValueMutationVariables
} from '../../../gql/typings';

const CustomFieldTag: React.FC<CustomFieldProps> = ({
  field,
  values,
  saveState: [, save],
}) => {
  const [editable] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [
    insert,
  ] = useMutation<UpsertCustomFieldValueMutation,
  UpsertCustomFieldValueMutationVariables>(UPSERT_MUTATION);
  const [
    removeCustomFieldValue
  ] = useMutation<RemoveCustomFieldValueMutation,
  RemoveCustomFieldValueMutationVariables>(REMOVE_MUTATION);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCreate = (value: any) => {
    if (value.value.length > 0) {
      const existingValues = values.nodes.length > 0 ? values.nodes.map(val => (val.value!)) : [];
      setSelected([...existingValues.map(e => e.value ?? ''), value]);

      save(prev => ([
        ...prev,
        recordId => insert({
          variables: {
            recordId,
            customFieldCode: field.code,
            customValues: [...existingValues.map(et => ({ valueId: et.id })), { newValue: value.value }],
          }
        }).then(() => setInputVisible(false))
      ]));
    } else {
      setInputVisible(false);
    }
  };


  const onRemoveValue = (value: string) => {
    const toRemoveLinkId = values?.nodes?.filter(v => v.value?.value === value)[0]!.id;
    setSelected(selected.filter(s => s !== value));
    save(prev => ([
      ...prev,
      () => removeCustomFieldValue({
        variables: {
          customValues: [toRemoveLinkId],
        }
      }),
    ]));
  };

  if (!field.accessRules?.allowMutation) return (
    <span>
      {values.nodes[0]?.value?.value}
    </span>
  );

  const settings = field.customFieldControlSetting;

  if (!field.isActive) {
    return <></>;
  }


  return (
    <Row>
      <Col span={settings?.isSynced ? 23 : 24}>
        <Space>
          <div className="custom-field-tag">
            {selected.map(it => (
              <Tag
                color={field.color || 'default'}
                closable
                onClose={() => onRemoveValue(it)}
                key={it.toString().trim().replaceAll(' ', '_')}
              >
                {it}
              </Tag>
            ))}
            {!editable && <>
              {inputVisible && (
                <Input
                  type="text"
                  size="small"
                  onPressEnter={e => onCreate(e)}
                  onBlur={e => onCreate(e)}
                  style={{ width: 78 }}
                />
              )}
              {!inputVisible && (
                <Tag className="site-tag-plus" color={field.color || 'default'} onClick={() => setInputVisible(true)}>
                  <PlusOutlined /> New Tag
                </Tag>
              )}
            </>}
          </div>
        </Space>
      </Col>
      {settings?.isSynced && (
        <Col span={1}>
          <Icon toolTip="is synchronized" style={{ marginTop: 8, marginLeft: 8 }} iconType="SYNC" />
        </Col>
      )}
    </Row>
  );
};

export default CustomFieldTag;
