import React, { useState } from 'react';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { Button, Col, Row, Skeleton } from 'antd';
import AddCard from '../../../../../components/ContactCard/AddCard';
import AdminCreateTerritoryModal from './AdminCreateTerritoryModal';
import AdminSiteBrickTerritoryCard from './AdminSiteBrickTerritoryCard';
import { PermissionEnum, SiteBrickTerritoryQueryQuery } from '../../../../../../gql/typings';
import { PermissionInfo } from '../../../../../permission/permissionUtils';

type AdminSiteBrickTerritoryProps = {
  permissions: {
    [PermissionEnum.BRICK]: PermissionInfo;
    [PermissionEnum.TERRITORY]: PermissionInfo;
  };
};

const AdminSiteBrickTerritories: React.FC<AdminSiteBrickTerritoryProps> = ({
  permissions: {
    BRICK: bricksPermission,
    TERRITORY: territoriesPermission,
  },
}) => {
  const { data, loading, refetch } = useQuery<SiteBrickTerritoryQueryQuery>(TERRITORY_CARD_QUERY);
  const createModalVisibleState = useState(false);

  return (
    <div className="site-brick-territory-admin-container">
      {data?.bricks.totalCount
        ? <Button type="link">There are {data.bricks.totalCount} unassigned bricks</Button>
        : ''}
      <Skeleton loading={loading} active={loading}>
        <Row gutter={12}>
          {(data?.territories.nodes ?? []).map(terr => (
            <Col key={terr.code} xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
              <AdminSiteBrickTerritoryCard
                territory={terr}
                territoryPermission={territoriesPermission}
                bricksPermission={bricksPermission}
              />
            </Col>
          ))}
          <AddCard
            label="Create new Territory"
            onClick={() => createModalVisibleState[1](true)}
          />
        </Row>
      </Skeleton>
      <AdminCreateTerritoryModal visibleState={createModalVisibleState} onRefetch={refetch} />
    </div>
  );
};

export const TERRITORY_CARD_QUERY = gql`
  query SiteBrickTerritoryQuery {
    bricks(criteria: { unassigned: true }) {
      hash
      totalCount
    }
    territories {
      hash
      nodes {
        code
        heading
        description
        countryCode
        bricks {
          hash
          totalCount
          siteTotalCount
          nodes {
            code
            heading
          }
        }
        responsibleUser {
          id
          fullname
        }
      }
    }
  }
`;

export default AdminSiteBrickTerritories;
