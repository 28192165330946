import React from 'react';
import { CustomFieldProps } from './CustomFieldUtils';
import CustomFieldSelect from './CustomFieldSelect';
import CustomFieldMultiSelect from './CustomFieldMultiSelect';


const CustomFieldSelectMultiSupport: React.FC<CustomFieldProps> = props => (
  <span>
    {props.field.allowMultiple
      ? <CustomFieldMultiSelect {...props} />
      : <CustomFieldSelect {...props} />}
  </span>
);


export default CustomFieldSelectMultiSupport;
